//api routes
const BaseUrl = 'https://api.eventmassi.com/';
const ApiPoint = BaseUrl + 'api/v2/admin/';

const ApiPoint2 = BaseUrl + 'api/v2/';

export const ApiRoute = {
  getCategories: ApiPoint + 'categories/get-all-categories',
  getVendors: ApiPoint + 'get-all-vendors',
  getVendors2: ApiPoint2 + 'get-all-vendors',
  getAllusersToSheet: ApiPoint2 + 'get-all-users-to-sheet',
  getVendorsByCategories: ApiPoint + 'get-vendorsByCategories',
  getVendorsByKeywords: ApiPoint + 'get-vendorsByKeywords',
  getTopVendorsWithPositions: ApiPoint + 'top-vendor-with-positions',
  getVendorsByStates: ApiPoint + 'get-vendorsByStates',
  getVendorById: (id) => `${ApiPoint}/getVendor?id=${id}`,
  updateVendorProfile: ApiPoint + 'update-vendor-profile',
  browseVendors: ApiPoint2 + 'browse-vendors',

  createKeywords: ApiPoint + 'keywords/create',
  getKeywords: ApiPoint + 'keywords/get-all-keywords',
  getKeywordById: ApiPoint + 'keywords/get-single-keyword',
  deleteKeyword: ApiPoint + 'keywords/delete-keyword',
  updateKeyword: ApiPoint + 'keywords/update-keyword',
  getDashboard: ApiPoint + '/get-all-dashboard',
  getPositions: ApiPoint + 'users/getAllVendors',
  assignPosition: ApiPoint + 'assign-position',
  deletePosition: ApiPoint + 'delete-position`',
  getPayments: ApiPoint + 'payments/get-all-payments',
  deletePayment: ApiPoint + 'payments/delete-payment',
  getSubscriptions: ApiPoint + 'subscriptions/get-all-subscriptions',
  createSubscription: ApiPoint + 'subscriptions/create',
  deleteSubscription: ApiPoint + 'subscriptions/delete-subscription',
  updateSubscription: ApiPoint + 'subscriptions/update-subscription',

  getFaqs: ApiPoint + 'faqs/get-all-faqs',
  getSliders: ApiPoint + 'slider/get-all-sliders',
  getStripeProducts: ApiPoint + 'subscriptions/get-stripe-products',
  getStripePrices: ApiPoint + 'subscriptions/get-stripe-prices',

  getContacts: ApiPoint2 + 'web/contact-us',
  login: ApiPoint + 'admin-management/login',
  getSubscribers: ApiPoint2 + 'web/get-subscribe-newsletter',
  postFaq: ApiPoint + 'faqs/create',
  getFaqById: ApiPoint + 'faqs/get-single-faq',
  getAdmins: ApiPoint + 'admin-management/get-all-admins',
  createAdmin: ApiPoint + 'admin-management/create-admin',
  deleteAdmin: ApiPoint + 'admin-management/delete-admin',
  getAdminById: ApiPoint + 'admin-management/get-single-admin',
  updateAdmin: ApiPoint + 'admin-management/update-admin',
  getStates: ApiPoint + 'states/get-all-states',
  createState: ApiPoint + 'states/create',
  getStateById: ApiPoint + 'states/get-single-state',
  updateState: ApiPoint + 'states/update-state',
  deleteState: ApiPoint + 'states/delete-state',
  getCategoryById: ApiPoint + 'categories/get-single-category',
  createCategory: ApiPoint + 'categories/create',
  updateCategory: ApiPoint + 'categories/udpate-category',
  deleteCategory: ApiPoint + 'categories/delete-category',
  getCategoryKeywords: (categoryId) =>
    `${ApiPoint}/categories/get-category-keyword?categoryId=${categoryId}`,
  createFaq: ApiPoint + 'faqs/create',
  updateFaq: ApiPoint + 'faqs//update-faq',
  deleteFaq: ApiPoint + 'faqs/delete-faq',

  createSlider: ApiPoint + 'slider/create',
  uploadSlider: ApiPoint2 + 'uploads/sliders',
  updateSlider: ApiPoint + 'slider/update-slider',
  deleteSlider: ApiPoint + 'slider/delete-slider',
  getSliderById: ApiPoint + 'slider/get-single-slider',

  deleteVendor: ApiPoint + 'deleteVendor',
  deleteVendorById: (vendorId) => `${ApiPoint}/deleteVendor?id=${vendorId}`,
  deleteSubscribeNewsLetter: ApiPoint2 + 'web/delete-subscribe-newsletter',
  uploadVendorMedia: ApiPoint2 + 'uploads/vendor-media',
  uploadVendorVideoMedia: ApiPoint2 + 'uploads/video/vendor-media',
  uploadVendorProfileImage: ApiPoint2 + 'uploads/vendor-profile-image',
  updateVendorProfile2: ApiPoint2 + 'update-vendor-profile',
  getDeals: ApiPoint + 'deals/get-deal',
  createDeal: ApiPoint + 'deals/create-deal',
  updateDeal: ApiPoint + 'deals/update-deal',
  deleteDeal: ApiPoint2 + 'delete-deal',
  overrideSubscriptionPreffered:
    ApiPoint + 'payments/override-subscription-preferred',
  overrideSubscriptionSelected:
    ApiPoint + 'payments/override-subscription-selected',
  revertToBasic: ApiPoint + 'payments/revert-to-basic',
  cancelSubscription: ApiPoint2 + 'cancel-subscription',
  updateSubscriptions: ApiPoint + 'payments/update-subscription',
  getSingleSubscription: ApiPoint + 'subscriptions/get-single-subscription',
};

export const URLS = {
  BaseUrl,
  ApiPoint,
};

export const CONSTANTS = {
  REQUEST_HEADERS: {
    Accept: 'application/json , text/plain, */*',
    'Content-Type': 'application/json',
  },
};
