import { CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
import { ApiRoute } from '../util/constants';
export const getDealById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getDeals}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Keyword');
};
export const updateDeal = async (id, name, description) => {
  let token = await getToken();
  let data = { id, name, description };
  let response = await fetch(`${ApiRoute.updateDeal}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Deals: ${response}`);
};
