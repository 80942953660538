import React, { Component, useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import { ApiRoute } from '../../util/constants';
import { Blocks } from 'react-loader-spinner';
import { getToken } from '../../services/AuthService';
import { getVendorById } from '../../services/VendorService';
import { getAllSubscription } from '../../services/SubscriptionService';

import './Pricing.css';

const Pricing = (props) => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [paid, setPaid] = useState([]);
  const [APIData2, setAPIData2] = useState([]);
  const [APIData3, setAPIData3] = useState([]);
  const [APIData4, setAPIData4] = useState([]);
  const [show, setShow] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [subscriptionDuration, setSubscriptionDuration] = useState('Monthly');
  const [isMounted, setIsMounted] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState([]);
  const [paymentId, setPaymentId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchVendorData = async () => {
    try {
      const response = await getVendorById(props.id);
      if (isMounted) {
        console.log('RESPONSE', response.data);
        setPaid(response.data);
        setUserId(response.data._id);
        setData(response.payment);
        setAPIData2(response?.payment?.subscription);
        setAPIData3(response?.payment?.subscription.plan);
        setSubscriptionId(response?.payment?.subscription.id);
        setPaymentId(response?.payment?._id);
        setIsLoading(false);
      }

      // setAPIData3(response.payment.subscription.latest_invoice.lines.data[0]);
      // setAPIData4(
      //   response.payment.subscription.latest_invoice.payment_intent
      //     .payment_method_types[0]
      // );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchVendorData();
    return () => {
      setIsMounted(false);
    };
  }, []);

  async function manuallyOverrideToPreferred() {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to upgrade this Vendor to preferred?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (confirmed.isConfirmed) {
      let token = await getToken();
      await fetch(`${ApiRoute.overrideSubscriptionPreffered}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ _id: userId }),
      }).then((resp) => {
        console.warn('resp', resp);
        resp.json().then((result) => {
          fetchVendorData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Upgraded To Preferred',
            showConfirmButton: false,
            timer: 1500,
          });
          console.warn('result', result);
        });
      });
    }
  }

  async function manuallyOverrideToSelected() {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to upgrade this Vendor to selected?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (confirmed.isConfirmed) {
      let token = await getToken();
      await fetch(`${ApiRoute.overrideSubscriptionSelected}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ _id: userId }),
      }).then((resp) => {
        console.warn('resp', resp);
        resp.json().then((result) => {
          fetchVendorData();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Upgraded To Selected',
            showConfirmButton: false,
            timer: 1500,
          });
          console.warn('result', result);
        });
      });
    }
  }

  async function revertToBasic() {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to Downgrade this Vendor to Basic?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (confirmed.isConfirmed) {
      let token = await getToken();
      await fetch(`${ApiRoute.revertToBasic}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ _id: userId }),
      }).then((resp) => {
        console.warn('resp', resp);
        resp.json().then((result) => {
          fetchVendorData();
          fetchSubscriptionData();
          handleGoBack();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Downgraded To Basic',
            showConfirmButton: false,
            timer: 1500,
          });
          console.warn('result', result);
        });
      });
    }
  }
  async function cancelSubscription() {
    const confirmed = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want the subscription to be canceled in stripe?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });

    if (confirmed.isConfirmed) {
      let token = await getToken();
      await fetch(`${ApiRoute.cancelSubscription}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ _id: paymentId }),
      }).then((resp) => {
        console.warn('resp', resp);
        resp.json().then((result) => {
          fetchVendorData();
          fetchSubscriptionData();

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Cancelled and Reverted To Basic',
            showConfirmButton: false,
            timer: 1500,
          });
          console.warn('result', result);
        });
      });
    }
  }

  const fetchSubscriptionData = async () => {
    try {
      const response = await getAllSubscription();
      setSubscriptionData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchVendorData();
    fetchSubscriptionData();
  }, []);
  const handleClick = () => {
    setShow(!show);
  };

  const handleGoBack = () => {
    setShow(false);
  };

  async function handleUpgradeClick(item) {
    let token = await getToken();
    let selectedPackage = null;
    let allpackages = item.packages;
    for (let i = 0; i < allpackages.length; i++) {
      if (allpackages[i].name.trim() === subscriptionDuration) {
        selectedPackage = allpackages[i];
      }
    }
    console.log('subscriptionDuration:', subscriptionDuration);
    console.log('allpackages:', allpackages);
    console.log('Package data Price:', selectedPackage.price_id);
    console.log('Package data:', selectedPackage);
    console.log('TOKEN', token);
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change your subscription package?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!',
    });

    if (confirmation.value) {
      const response = await fetch(`${ApiRoute.updateSubscriptions}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + token,
        },
        body: JSON.stringify({
          price_id: selectedPackage.price_id,
          subscription_id: subscriptionId,
          _id: paymentId,
        }),
      });

      const result = await response.json();
      console.log(result);

      if (response.status === 200) {
        fetchVendorData();
        fetchSubscriptionData();
        handleGoBack();
        Swal.fire({
          title: 'Package Changed',
          text: 'Your subscription package has been changed successfully.',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while changing the subscription package.',
          icon: 'error',
        });
      }
    }
  }

  function changeDuration(value) {
    value
      ? setSubscriptionDuration('Annually')
      : setSubscriptionDuration('Monthly');
  }
  const filteredData = subscriptionData.filter(
    (packagedata) => packagedata.name !== 'FREE Basic'
  );
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Blocks type="ThreeDots" color="#00BFFF" height={80} width={80} />
        </div>
      ) : (
        <div className=" text-center">
          <div className=" text-center">
            {!paid?.override && paid?.isPaid && (
              <>
                <button
                  onClick={handleClick}
                  className="contact bg-dark col-lg-3 col-6 text-center text-light justify-content-center"
                >
                  Change Plan
                </button>
                <button
                  onClick={cancelSubscription}
                  type="button"
                  className="btn col-lg-3 col-6 btn-danger mt-3"
                >
                  REVERT TO BASIC
                </button>
              </>
            )}

            {/* {paid?.isPaid && (
            <button
              onClick={handleClick}
              className="contact bg-dark w-25 text-center text-light justify-content-center"
            >
              Change Plan
            </button>
          )} */}
            {/* <button
            onClick={handleClick}
            className="contact bg-dark w-25 text-center text-light justify-content-center  "
          >
            Change Plan
          </button> */}
            {show && (
              <React.Fragment>
                <div className="container">
                  <div className="row text-center subscription-duration">
                    <label className="switch mx-auto d-flex mt-2">
                      <input
                        type="checkbox"
                        onChange={(e) => changeDuration(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="row">
                    {filteredData.map((packagedata, pData) => {
                      return (
                        <div className="col-sm-6 col-md-4">
                          <div className="card mb-3">
                            <div className="card-body">
                              <h5 className="card-title">
                                {packagedata.business}
                              </h5>

                              <h3 className="fw-bold">{packagedata.name}</h3>
                              <span className="value fw-bold">
                                {packagedata.packages.map((price) => {
                                  return packagedata.name === 'FREE Basic'
                                    ? 'Free'
                                    : price.name.trim() === subscriptionDuration
                                    ? '$' + price.price
                                    : null;
                                })}
                                {/* return {
                                    price: price.price,
                                  }; */}

                                <span className="fs-5 monthly text-left">
                                  <span className="mx-2">/</span>{' '}
                                  {subscriptionDuration}
                                </span>
                              </span>
                              {packagedata.services.length > 0 ? (
                                <div className="plan-features">
                                  <h6 className="text-left mb-2  fw-bold">
                                    What's Included
                                  </h6>
                                  <ul>
                                    {packagedata.services.map(
                                      (service, services) => {
                                        return (
                                          <li
                                            className="text-left  fw-bold"
                                            key={services}
                                          >
                                            {service.description}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              ) : null}
                              <p className="card-text"></p>
                              <button
                                onClick={() => handleUpgradeClick(packagedata)}
                                type="button"
                                className="btn btn-primary"
                              >
                                Upgrade
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <button
                  className="contact bg-dark w-25 text-center text-light justify-content-center  "
                  onClick={handleGoBack}
                >
                  Go Back
                </button>
              </React.Fragment>
            )}
          </div>
          {data && !show ? (
            <Container fluid>
              <Row className="justify-content-center ">
                <Col lg={5}>
                  <div className="text-center mt-3 mb-5">
                    <h4>Your Subscription Plan</h4>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xl={4} sm={6}>
                  <Card className="pricing-box">
                    <CardBody className="p-4 ">
                      <div className="text-center">
                        {APIData3.amount / 100 === 300 ||
                        APIData3.amount / 100 === 29.99 ? (
                          <div className=" text-center fw-bolder lh-lg  ">
                            <img
                              style={{ width: '30px' }}
                              src="https://www.beta.eventmassi.com/_next/static/media/silver-star-icon.1d3735b4.svg"
                              alt="im"
                            ></img>
                            <h5>Preferred</h5>
                            <h5>For Large Event Businesses</h5>
                          </div>
                        ) : (
                          <div className=" text-center fw-bolder lh-lg  ">
                            <img
                              style={{ width: '30px' }}
                              src="https://www.beta.eventmassi.com/_next/static/media/gold-star-icon.846ea658.svg"
                              alt="im"
                            ></img>
                            <h5>Select</h5>
                            <h5>Perfect for Small Business</h5>
                          </div>
                        )}

                        <div className="font-size-14 mt-4 pt-2">
                          <ul className="list-unstyled plan-features">
                            {APIData3.amount / 100 === 300 ||
                            APIData3.amount / 100 === 29.99 ? (
                              <div>
                                {subscriptionData
                                  .filter(
                                    (packagedata) =>
                                      packagedata.name === 'Massi Preferred'
                                  )
                                  .map((packagedata, pData) => {
                                    return (
                                      <div
                                        className="mx-auto d-flex justify-content-center "
                                        key={pData}
                                      >
                                        {packagedata.services.length > 0 && (
                                          <div className="plan-feature">
                                            <h4 className="text-left mb-2 fw-bold">
                                              What's Included
                                            </h4>
                                            <ul>
                                              {packagedata.services.map(
                                                (service, services) => {
                                                  return (
                                                    <li
                                                      className="text-left fw-bold"
                                                      key={services}
                                                    >
                                                      {service.description}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div>
                                {subscriptionData
                                  .filter(
                                    (packagedata) =>
                                      packagedata.name === 'Massi Select'
                                  )
                                  .map((packagedata, pData) => {
                                    return (
                                      <div
                                        className="mx-auto d-flex justify-content-center "
                                        key={pData}
                                      >
                                        {packagedata.services.length > 0 && (
                                          <div className="plan-feature">
                                            <h4 className="text-left mb-2 fw-bold">
                                              What's Included
                                            </h4>
                                            <ul>
                                              {packagedata.services.map(
                                                (service, services) => {
                                                  return (
                                                    <li
                                                      className="text-left fw-bold"
                                                      key={services}
                                                    >
                                                      {service.description}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </ul>
                        </div>

                        <div>
                          <h1 className="fw-bold mb-1">
                            <sup className="me-1">
                              <small>$</small>
                            </sup>
                            {APIData3.amount / 100}
                          </h1>
                          <p className="text-muted">Per {APIData3.interval}</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="bg row d-flex justify-content-center ">
                <div className=" col-sm-6  mobile">
                  <div className="bg-white shadow-lg border border-3 rounded-4  p-5 d-flex flex-column">
                    <div className="d-flex">
                      <h5 className="col-lg-6 col-3 fw-bolder lh-lg">Email</h5>
                      <div className="ml-auto">
                        <h5 className="lh-lg">{data.email}</h5>
                      </div>
                    </div>

                    <div className="d-flex">
                      <h5 className="col-6 fw-bolder lh-lg">Order Date</h5>
                      <div className="ml-auto">
                        <h5 className="lh-lg">
                          <Moment unix format="YYYY-MM-DD HH:mm:ss">
                            {data.start_date}
                          </Moment>
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex">
                      <h5 className="col-6 fw-bolder lh-lg">Currency</h5>
                      <div className="ml-auto ">
                        <h5 className="lh-lg mx-5">{APIData2.currency}</h5>
                      </div>
                    </div>

                    <div className="d-flex">
                      <h5 className="col-6 fw-bolder lh-lg">
                        {' '}
                        Period Start Date
                      </h5>
                      <h5 className="lh-lg">
                        <Moment unix format="YYYY-MM-DD HH:mm:ss">
                          {APIData2.current_period_start}
                        </Moment>
                      </h5>
                    </div>
                    <div className="d-flex">
                      <h5 className="col-6 fw-bolder lh-lg">Period End Date</h5>
                      <h5 className="lh-lg">
                        <Moment unix format="YYYY-MM-DD HH:mm:ss">
                          {APIData2.current_period_end}
                        </Moment>{' '}
                      </h5>
                    </div>

                    <hr></hr>
                    <div className="d-flex">
                      <h5 className="col-6 fw-bolder ">Total</h5>
                      <div className="ml-auto ">
                        <h5 className="fw-bolder mx-5 ">
                          {APIData3.amount / 100}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          ) : (
            <div>
              {paid?.isPaid === false ? (
                <Card className="w-75 mx-auto">
                  <CardBody>
                    <div>SUBSCRIPTION NOT AVAILABLE</div>

                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="card mb-3 bg-dark">
                          <div className="card-body">
                            <h5 className="card-title text-light">
                              For Large Event Businesses
                            </h5>

                            <h3 className="fw-bold text-light">Preferred</h3>

                            <p className="card-text"></p>
                            <button
                              onClick={manuallyOverrideToPreferred}
                              type="button"
                              className="btn btn-primary"
                            >
                              UPGRADE
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="card mb-3 bg-dark">
                          <div className="card-body">
                            <h5 className="card-title text-light">
                              For Small Event Businesses
                            </h5>

                            <h3 className="fw-bold text-light">Select</h3>

                            <p className="card-text"></p>
                            <button
                              onClick={manuallyOverrideToSelected}
                              type="button"
                              className="btn btn-primary"
                            >
                              UPGRADE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <button
                    onClick={manuallyOverrideToPreferred}
                    className="bg-primary text-light mt-3 p-2"
                  >
                    OVERRIDE TO PREFERRED VENDOR
                  </button>
                  <br></br>
                  <button
                    onClick={manuallyOverrideToSelected}
                    className="bg-primary text-light mt-3 p-2"
                  >
                    OVERRIDE TO SELECTED VENDOR
                  </button> */}
                  </CardBody>
                </Card>
              ) : (
                <div>
                  {paid.override ? (
                    <div>
                      {paid.position === 0 ? (
                        <div>VENDOR MANUALLY OVERRIDED TO SELECTED</div>
                      ) : (
                        <div>VENDOR MANUALLY OVERRIDED TO PREFERRED</div>
                      )}

                      <button
                        onClick={revertToBasic}
                        type="button"
                        className="btn btn-danger mt-3"
                      >
                        OVERRIDE TO BASIC
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Pricing;

// <div>SUBSCRIPTION NOT AVAILABLE</div>
//         <button
//           onClick={manuallyOverrideToPaid}
//           className="bg-primary text-light"
//         >
//           MANUALLY OVERRIDE TO PAID VENDOR
//         </button>
