import React, { useState, useEffect } from 'react';
import { getAllSliders } from '../../services/SliderService';
import { uploadSlider } from '../../services/SliderService';
import { ApiRoute } from '../../util/constants';
import { getToken } from '../../services/AuthService';
import { updateSlider } from '../../services/SliderService';
import { getSliderById } from '../../services/SliderService';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Spinner } from 'react-bootstrap';

import { Modal } from 'react-bootstrap';
import { Blocks } from 'react-loader-spinner';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Sliders = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [data, setData] = useState([]);
  const [image, setImage] = useState({});
  const [preview, setPreview] = useState([]);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Sliders', link: '#' },
  ]);

  const fetchSliderData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllSliders();
      const data = await response.data;
      setData(data);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  // useEffect(() => {
  useEffect(() => {
    fetchSliderData();
  }, []);

  async function addSlider(e) {
    let token = await getToken();
    e.preventDefault();
    let data = { image };
    const formData = new FormData();
    formData.append('image', image);

    if (!image) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Select An Image',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      setIsLoading(true); // Start the loading spinner
      fetch(`${ApiRoute.uploadSlider}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      }).then((resp) => {
        console.warn('resp', resp);
        resp
          .json()
          .then((result) => {
            console.warn('result', result);
            fetch(`${ApiRoute.createSlider}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },

              body: JSON.stringify(result),
            }).then((resp) => {
              console.warn('resp', resp);
              resp
                .json()
                .then((result) => {
                  console.warn('result', result);

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'SuccessFully Added',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  fetchSliderData();
                  closeModal();
                  setImage('');
                })
                .catch((e) => alert(e, 'NETWORK ERROR'))
                .finally(() => {
                  setIsLoading(false); // Stop the loading spinner
                });
            });
            // Swal.fire({
            //   position: 'center',
            //   icon: 'success',
            //   title: 'SuccessFully Added',
            //   showConfirmButton: false,
            //   timer: 1500,
            // });
            // fetchSliderData();
            // closeModal();
            // setImage('');
          })
          .catch((e) => alert(e, 'NETWORK ERROR'))
          .finally(() => {
            setIsLoading(false); // Stop the loading spinner
          });
      });
    }
  }

  async function getByID(id) {
    try {
      const response = await getSliderById(id);
      setPlaceholder(response.data.id);

      openModal2();
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  }

  async function updateByID(e) {
    e.preventDefault();

    let token = await getToken();
    const formData = new FormData();
    formData.append('image', image);

    if (!image) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        setIsLoading(true);

        const uploadResponse = await fetch(`${ApiRoute.uploadSlider}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: formData,
        });

        const uploadResult = await uploadResponse.json();
        const imagePath = uploadResult.imagePath;

        const updateResponse = await updateSlider(placeholder, imagePath);
        setIsLoading(false);

        if (updateResponse.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          fetchSliderData();
          setImage('');
        }
      } catch (err) {
        setIsLoading(false);

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
    }
  }

  async function deleteSlider(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn(id);
        fetch(`${ApiRoute.deleteSlider}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);

            fetchSliderData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setPreview(false);
    setImage(false);
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);
    setPreview(false);
    setImage(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={addSlider} class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  {/* <div class="form-group ">
                    <label for="title">Title</label>
                    <input
                      type="file"
                      class="form-control "
                      name="title"
                      // value={image}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div> */}
                  <div class="form-group">
                    <label for="title">Title</label>
                    <input
                      type="file"
                      class="form-control"
                      name="title"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setImage(file);
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          setPreview(reader.result);
                        };
                      }}
                    />

                    {preview && (
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ width: '30%' }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        type="submit"
                        className="btn btn-success mx-1"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        ) : (
                          'Add'
                        )}
                      </button>
                      {/* <button type="submit" class="btn btn-success mx-1">
                        Add
                      </button> */}
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal show={isOpen2} onHide={closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  {/* <div class="form-group ">
                    <label for="title">Title</label>
                    <input
                      type="file"
                      class="form-control "
                      name="title"
                      // value={image}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div> */}

                  <div class="form-group">
                    <label for="title">Title</label>
                    <input
                      type="file"
                      class="form-control"
                      name="title"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setImage(file);
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          setPreview(reader.result);
                        };
                      }}
                    />

                    {preview && (
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ width: '30%' }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={updateByID}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Update
                      </button>
                      <Button variant="secondary" onClick={closeModal2}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="Sliders" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col lg="12">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={openModal}
                    // onClick={this.handleCustomerClicks}
                  >
                    <i className="mdi mdi-plus me-1" /> Add SLIDER
                  </Button>
                </div>
              </Col>
              <Card>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '200',
                    }}
                  >
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                    />
                  </div>
                ) : (
                  <CardBody className="overflow-auto ">
                    <table class="table table-hover table-light bg-light text-dark ">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Image</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td className="w-100">
                                  <td>
                                    <img
                                      height={90}
                                      width={'auto'}
                                      src={
                                        'https://massi-bucket.s3.amazonaws.com/' +
                                        item.imagePath
                                      }
                                      alt="Slider"
                                    ></img>
                                  </td>
                                </td>

                                <div className="text-right">
                                  <Button
                                    onClick={() => getByID(item.id)}
                                    className="bg-primary mx-2 my-2  "
                                  >
                                    <i class="ri-edit-box-line"></i>
                                  </Button>

                                  <Button
                                    onClick={() => deleteSlider(item.id)}
                                    className="bg-danger mx-2 "
                                  >
                                    <i class="ri-delete-bin-6-fill"></i>
                                  </Button>
                                </div>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Sliders;
