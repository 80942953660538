import React from 'react';

import { useEffect, useState } from 'react';
import { getVendorById } from '../../services/VendorService';
import { getAllContacts } from '../../services/ContactService';
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardContent,
  CardBody,
  Label,
  Input,
  Form,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import './VendorMessage.css';
import Moment from 'react-moment';
const VendorMessages = (props) => {
  const [MessageData, setMessageData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [inquiriesData, setInquiriesData] = useState(null);
  const [inquiriesThisMonth, setInquiriesThisMonth] = useState(null);
  // const [views, setViews] = useState([]);
  useEffect(() => {
    let isMounted = true;

    const fetchVendorData = async () => {
      try {
        const inquiries = await getAllContacts();
        const inquiriesData = inquiries.data.length;
        const inquiriesMonthly = inquiries.data;
        console.log(inquiriesMonthly, 'DATA FROM CONTACTS');
        const response = await getVendorById(props.id);

        if (isMounted) {
          // setViews(response.data.views);
          setInquiriesThisMonth(inquiriesMonthly);
          setMessageData(response.contact);
          setInquiriesData(inquiriesData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchVendorData();

    return () => {
      isMounted = false;
    };
  }, []);

  // const fetchVendorData = async () => {
  //   try {
  //     const inquiries = await getAllContacts();
  //     const inquiriesData = await inquiries.data.length;
  //     const inquiriesMonthly = await inquiries.data;
  //     console.log(inquiriesMonthly, 'DATA FROM CONTACTS');
  //     const response = await getVendorById(props.id);

  //     setViews(response.data.views);
  //     setInquiriesThisMonth(inquiriesMonthly);
  //     setMessageData(response.contact);
  //     setInquiriesData(inquiriesData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchVendorData();
  // }, []);
  const today = new Date();
  const thisMonth = today.getMonth();
  const filteredInquiries = inquiriesThisMonth
    ? inquiriesThisMonth.filter((inquiry) => {
        const inquiryDate = new Date(inquiry.createdAt);
        return inquiryDate.getMonth() === thisMonth;
      })
    : [];

  return (
    <div>
      <Row>
        {/* <Col md={4}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <p className="text-truncate font-size-14 ">Visitors</p>

                  <h4 className="mb-0">{views}</h4>
                </div>

                <div className="text-primary">
                  <i className={'ri-survey-fill' + ' font-size-24'}></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col md={6}>
          <Card>
            <CardBody>
              <Link to="/contact">
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate text-dark font-size-14 ">
                      Total Inquiries (EventMassi)
                    </p>

                    <h4 className="mb-0">{inquiriesData}</h4>
                  </div>

                  <div className="text-primary">
                    <i
                      className={' ri-question-answer-fill' + ' font-size-24'}
                    ></i>
                  </div>
                </div>
              </Link>
            </CardBody>
          </Card>
        </Col>
        {filteredInquiries.length ? (
          <Col md={6}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 ">
                      Inquiries This Month (EventMassi)
                    </p>

                    <h4 className="mb-0">{filteredInquiries.length}</h4>
                  </div>

                  <div className="text-primary">
                    <i className={' ri-discuss-fill' + ' font-size-24'}></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>
      {/* <Card>
        <CardHeader title="Inquiries this month" />

        <p>Number of inquiries: {filteredInquiries.length}</p>
      </Card> */}
      <div className="col-lg-12 col-md-12">
        <div className="messages-container margin-top-5">
          <div className="messages-headline">
            <h4>Messages</h4>
          </div>
          <div className="messages-container-inner">
            <div className="messages-inbox">
              <ul>
                <div className="left-container">
                  {MessageData.length ? (
                    <div className="box-container">
                      {MessageData.map((user, key) => {
                        return (
                          <div
                            key={key}
                            className={`user-box user-name-link-hover ${
                              selectedUser === user && 'selected'
                            }`}
                            onClick={() => setSelectedUser(user)}
                            style={{ cursor: 'pointer' }}
                            // key={key}
                            // className="user-box user-name-link-hover"
                            // onClick={() => setSelectedUser(user)}
                            // style={{
                            //   cursor: 'pointer',
                            // }}
                          >
                            <li className="">
                              <a href="#!">
                                <div className="message-avatar">
                                  <img
                                    src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                                    alt="zeeshan"
                                  />
                                </div>
                                <div className="message-by">
                                  <div className="message-by-headline">
                                    <h6>{user.name}</h6>
                                  </div>
                                  <p>{user.email}</p>
                                  <span>
                                    <Moment fromNow>{user.createdAt}</Moment>
                                  </span>
                                </div>
                              </a>
                            </li>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </ul>
            </div>
            {selectedUser ? (
              <div className="message-content">
                <div className="dashboard-list-box margin-top-0">
                  <ul>
                    <li className="pending-booking">
                      <div className="list-box-listing bookings">
                        <div className="inner">
                          <h3>
                            {selectedUser.name}
                            <span className="booking-status pending">
                              {selectedUser.email}
                            </span>
                          </h3>
                          <div className="inner-booking-list">
                            <h5>Message Date:</h5>{' '}
                            <ul className="booking-list">
                              <li className="highlighted">
                                <Moment format="DD MMMM YYYY, dddd">
                                  {selectedUser.createdAt}
                                </Moment>
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Date of Event:</h5>{' '}
                            <ul className="booking-list">
                              <li className="highlighted">
                                <Moment format="DD MMMM YYYY, dddd">
                                  {selectedUser.desiredDate}
                                </Moment>
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Guests:</h5>{' '}
                            <ul className="booking-list">
                              <li className="highlighted">
                                {selectedUser.guestCount} Guests
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Phone:</h5>{' '}
                            <ul className="booking-list">
                              {selectedUser.phone}
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Message:</h5>
                            <ul className="booking-list">
                              <li>{selectedUser.message}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorMessages;

{
  /* <Col md={4}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="flex-1 overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Total Inquiries
                </p>

                <h4 className="mb-0">{inquiriesData}</h4>
              </div>

              <div className="text-primary">
                <i className={' ri-question-answer-fill' + ' font-size-24'}></i>
              </div>
            </div>
          </CardBody>

          <CardBody className="border-top py-3">
            <div className="text-truncate"></div>
          </CardBody>
        </Card>
      </Col>
      <div className="d-flex">
        <div className="left-container">
          {MessageData.length ? (
            <div className="box-container">
              {MessageData.map((user, key) => {
                return (
                  <div
                    key={key}
                    className="user-box user-name-link-hover"
                    onClick={() => setSelectedUser(user)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                      width={45}
                      height={40}
                      className="user-image"
                      alt="no-image"
                    />
                    <div className="user-name-link ">
                      <h5 className="text-light ">{user.name}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>No data found</p>
          )}
        </div>

        <div class="container-fluid ">
          <div class="row">
            <div class="col-sm-6">
              {selectedUser ? (
                <div className="user-info-box ">
                  <React.Fragment>
                    <h6>
                      <span className="highlighted"> {selectedUser.email}</span>
                    </h6>

                    <h6>Phone: {selectedUser.phone}</h6>
                    <h6>Booking Details: {selectedUser.guestCount}</h6>

                    <h6>
                      Date:
                      <Moment
                        className="highlighted"
                        format="DD MMMM YYYY, dddd"
                      >
                        {selectedUser.desiredDate}
                      </Moment>
                    </h6>

                    <h5>Message: {selectedUser.message}</h5>
                    <span>
                      <Moment fromNow>{selectedUser.createdAt}</Moment>
                    </span>
                  </React.Fragment>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div> */
}
