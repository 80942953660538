import React from 'react';
import { getToken } from '../../services/AuthService';
import { useEffect, useState } from 'react';
import { getVendorById } from '../../services/VendorService';
import { ApiRoute } from '../../util/constants';
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Label,
  Input,
  Form,
} from 'reactstrap';
import './VendorImage.css';

import Swal from 'sweetalert2';
const VendorImages = (props) => {
  const [data, setData] = useState([]);
  const [image, setImage] = useState({});
  const [video, setVideo] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const fetchVendorData = async () => {
    try {
      const response = await getVendorById(props.id);
      if (isMounted) {
        setAssets(response.data.assets);

        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVendorData();
    return () => {
      setIsMounted(false);
    };
  }, []);

  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchVendorData = async () => {
  //     try {
  //       const response = await getVendorById(props.id);
  //       if (isMounted) {
  //         setAssets(response.data.assets);
  //         setData(response.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchVendorData();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [props.id]);

  async function addSlider(e) {
    let token = await getToken();
    e.preventDefault();

    let data = { image };

    const formData = new FormData();

    formData.append('image', image);
    console.log('IMAGE', image);

    fetch(`${ApiRoute.uploadVendorMedia}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    }).then((resp) => {
      resp
        .json()
        .then((result) => {
          let newAssets = assets.concat([
            { type: 'image', url: result.imagePath },
          ]);
          console.warn('RESPONSE from DATA', result);
          fetch(`${ApiRoute.updateVendorProfile}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({
              id: props.id,
              assets: newAssets,
            }),
          }).then((resp) => {
            console.warn('resp', resp);
            resp
              .json()
              .then((result) => {
                console.warn('result', result);
                if (result?.success) {
                  fetchVendorData();
                  setImage('');
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'SuccessFully Added',
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              })
              .catch((error) => {
                console.error('Error:', error.message);
                Swal.fire({
                  title: 'Error!',
                  text: 'Network Error',
                  icon: 'error',
                  confirmButtonText: 'OK',
                });
              });
          });
        })
        .catch((error) => {
          console.error('Error:', error.message);
          Swal.fire({
            title: 'Error!',
            text: 'Network Error',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        });
    });
  }
  async function addVideo(e) {
    let token = await getToken();
    e.preventDefault();
    let data = { video };
    const formData = new FormData();

    formData.append('video', video);

    fetch(`${ApiRoute.uploadVendorVideoMedia}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    }).then((resp) => {
      resp
        .json()
        .then((result) => {
          let newAssets = assets.concat([
            { type: 'video', url: result.imagePath },
          ]);
          console.warn('RESPONSE from DATA', result);
          fetch(`${ApiRoute.updateVendorProfile}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({
              id: props.id,
              assets: newAssets,
            }),
          }).then((resp) => {
            console.warn('resp', resp);
            resp
              .json()
              .then((result) => {
                console.warn('result', result);
                if (result?.success) {
                  fetchVendorData();
                  setImage('');
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'SuccessFully Added',
                    showConfirmButton: false,
                    timer: 2000,
                  });
                }
              })
              .catch((e) => alert(e, 'NETWORK ERROR'));
          });
        })
        .catch((e) => alert(e, 'NETWORK ERROR'));
    });
  }
  async function deleteImage(index) {
    console.log('INDEX', index);
    const { value: confirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmed) {
      let token = await getToken();

      let newAssets = [...assets];
      newAssets.splice(index, 1);
      fetch(`${ApiRoute.updateVendorProfile}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          id: props.id,
          assets: newAssets,
        }),
      }).then((resp) => {
        resp.json().then((result) => {
          if (result.success) {
            fetchVendorData();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'SuccessFully Deleted',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
      });
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row>
          <Card>
            <CardBody>
              {/* <div className="container "> */}
              {/* <div className="row">
                <div className="container"> */}
              {/* <div className="dashboard-list-box ">
                <div className="dashboard-list-box-static bg-light-gray"> */}
              <div className="edit-profile-photo">
                <div className="change-photo-btn">
                  <div>
                    <Form onSubmit={addSlider}>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="basicpill-pancard-input18"
                            >
                              Upload Image
                            </Label>
                            <Input
                              type="file"
                              onChange={(e) => {
                                setImage(e.target.files[0]);
                              }}
                              required
                              className="form-control"
                              id="basicpill-pancard-input18"
                            />

                            <button type="submit" className="btn btn-dark mt-3">
                              Add
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <Form onSubmit={addVideo}>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="basicpill-pancard-input18"
                            >
                              Upload Video
                            </Label>
                            <Input
                              type="file"
                              onChange={(e) => {
                                setVideo(e.target.files[0]);
                              }}
                              className="form-control"
                              required
                              id="basicpill-pancard-input18"
                            />

                            <button type="submit" className="btn btn-dark mt-3">
                              Add
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>

                    <div className="row justify-content-center text-center">
                      {assets.map((asset, index) => {
                        if (asset.path || asset.url) {
                          if (asset.type === 'image') {
                            return (
                              <div key={index} className="col-md-6">
                                <img
                                  src={`https://massi-bucket.s3.amazonaws.com/${
                                    asset.path || asset.url
                                  }`}
                                  alt="img"
                                  className="image-half same-size"
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteImage(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            );
                          } else if (asset.type === 'video') {
                            return (
                              <div key={index} className="col-md-6">
                                <video
                                  controls
                                  className="image-half same-size"
                                >
                                  <source
                                    src={`https://massi-bucket.s3.amazonaws.com/${
                                      asset.path || asset.url
                                    }`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteImage(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>

                    {/* <div className="row justify-content-center text-center">
                      <div className="col-lg-7">
                        {assets.map((user, index) => {
                          if (user.path || user.url) {
                            if (user.type === 'image') {
                              return (
                                <>
                                  <img
                                    src={`https://massi-bucket.s3.amazonaws.com/${
                                      user.path || user.url
                                    }`}
                                    alt="img"
                                    className="image-half same-size"
                                  />
                                </>
                              );
                            } else if (user.type === 'video') {
                              return (
                                <>
                                  <video
                                    controls
                                    className="image-half same-size"
                                  >
                                    <source
                                      src={`https://massi-bucket.s3.amazonaws.com/${
                                        user.path || user.url
                                      }`}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </>
                              );
                            }
                          }
                        })}
                      </div>
                    </div> */}
                    {/* <Button
                      className="bg-danger button"
                      onClick={() => deleteImage(index)}
                    >
                      <i className="ri-delete-bin-6-fill"></i>
                    </Button>
                    <Button
                      className="bg-danger button"
                      onClick={() => deleteImage(user._id)}
                    >
                      <i className="ri-delete-bin-6-fill"></i>
                    </Button> */}
                    {/* <div className="row justify-content-center text-center">
                      <div className="col-lg-7">
                        {assets.map((user) => {
                          if (user.path || user.url) {
                            return (
                              <>
                                <Button
                                  className="bg-danger button"
                                  onClick={() => deleteImage(user._id)}
                                >
                                  <i className="ri-delete-bin-6-fill"></i>
                                </Button>
                                <img
                                  src={`https://massi-bucket.s3.amazonaws.com/${
                                    user.path || user.url
                                  }`}
                                  alt="img"
                                  className="image-half same-size"
                                />
                              </>
                            );
                          }
                        })}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* </div>
              </div> */}
              {/* </div>
              </div> */}
              {/* </div> */}
            </CardBody>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VendorImages;
