import React, { useState, useEffect } from 'react';
import { getAllSubscribers } from '../../services/SubscriberService';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import { getToken } from '../../services/AuthService';

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
} from 'reactstrap';
import { Blocks } from 'react-loader-spinner';
import { ApiRoute } from '../../util/constants';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Subscriber = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [serialNumber, setSerialNumber] = useState(1);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Subscribers', link: '#' },
  ]);
  const fetchSubscriberData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllSubscribers();
      const data = await response.data;
      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);

      setData(data);
      setTotalPages(pages);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    let previousDataIndex = (page - 1) * perPage;

    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }
  let filteredData = data.filter((row) => {
    return row.email.toLowerCase().includes(searchTerm.toLowerCase());
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    fetchSubscriberData();
  }, []);

  async function deleteSubscriber(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${ApiRoute.deleteSubscribeNewsLetter}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);
            fetchSubscriberData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Subscriber SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Subscribers" breadcrumbItems={breadcrumbItems} />
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Search </h4>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Via Email"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <Button
                      className="btn btn-info"
                      onClick={() => setSearchTerm('')}
                      disabled={searchTerm === ''}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '200',
                      }}
                    >
                      <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                      />
                    </div>
                  ) : (
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Email</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.map((emp, key) => (
                            <tr key={key}>
                              <td className="w-25">
                                {currentPage * perPage + key + 1}
                              </td>
                              {/* <td className="w-25">{key + 1}</td> */}
                              <td className="w-75">{emp.email}</td>

                              <td>
                                <Button
                                  onClick={() => deleteSubscriber(emp.id)}
                                  className="bg-danger"
                                >
                                  <i class="ri-delete-bin-6-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Row>
                        <Col lg="3">
                          <div className="btn-group mx-2">
                            <select
                              className="bg-secondary text-light"
                              value={perPage}
                              onChange={handleSelect}
                              style={{
                                fontSize: '1.4em',
                                padding: '1.5px',
                              }}
                            >
                              {perPageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col lg="9">
                          <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel="..."
                            pageCount={totalPages}
                            onPageChange={(page) =>
                              handlePageClick(page.selected + 1)
                            }
                            containerClassName={'pagination'}
                            previousLinkClassName={'pagination__link'}
                            nextLinkClassName={'pagination__link'}
                            disabledClassName={'pagination__link--disabled'}
                            activeClassName={'item active '}
                            disableInitialCallback={true}
                            initialPage={currentPage}
                            forcePage={currentPage}
                          />
                        </Col>
                      </Row>
                      {/* <Col lg="3">
                        <div className="btn-group mx-4">
                          <select
                            className="bg-secondary text-light"
                            value={perPage}
                            onChange={handleSelect}
                            style={{
                              fontSize: '1.4em',
                              padding: '1.5px',
                            }}
                          >
                            {perPageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg="12">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel="..."
                          pageCount={totalPages}
                          onPageChange={(page) =>
                            handlePageClick(page.selected + 1)
                          }
                          containerClassName={'pagination'}
                          previousLinkClassName={'pagination__link'}
                          nextLinkClassName={'pagination__link'}
                          disabledClassName={'pagination__link--disabled'}
                          activeClassName={'item active '}
                          disableInitialCallback={true}
                          initialPage={currentPage}
                          forcePage={currentPage}
                        />
                      </Col> */}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Subscriber;
