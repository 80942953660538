import React, { useState, useEffect } from 'react';
import { Container, CardBody, Col, Card, Button, Row } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { Blocks } from 'react-loader-spinner';
import { getAllPayments, deletePayment } from '../../services/PaymentService';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Swal from 'sweetalert2';
const IndexKanban = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Payments', link: '#' },
  ]);
  const fetchPaymentData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllPayments();
      const data = await response.data;
      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);
      setData(data);
      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  let filteredData = data.filter((row) => {
    return (
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.amount.toString().includes(searchTerm.toString()) ||
      row.interval.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  useEffect(() => {
    fetchPaymentData();
  }, []);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    console.log(currentDataIndex);
    let previousDataIndex = (page - 1) * perPage;
    console.log(previousDataIndex);
    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });
    console.log(currentPageDataArray);
    setCurrentPageData(currentPageDataArray);
  }

  async function deletePay(id) {
    console.log('ID OF SELECTED PAYMENT', id);
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        // in result you have the answer given by user from modal
        if (result.isConfirmed) {
          // if the user give yes answer then it means we need to call delete api
          const response = await deletePayment(id);
          console.log('response', response.success);
          // delete api will return response which then further checked if it is tru or not
          if (response.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Payment SuccessFully Deleted',
              showConfirmButton: false,
              timer: 1500,
            });
            fetchPaymentData();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'SERVER ERROR',
              showConfirmButton: false,
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Payments" breadcrumbItems={breadcrumbItems} />
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Search </h4>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Payments"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Button
                      className="btn btn-info"
                      onClick={() => setSearchTerm('')}
                      disabled={searchTerm === ''}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '200',
              }}
            >
              <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            </div>
          ) : (
            <Card>
              <CardBody className="overflow-auto">
                <div>
                  <table className="table table-hover table-light bg-light text-dark ">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Interval</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData.length > 0 &&
                        currentPageData.map((emp, key) => (
                          <tr key={key}>
                            <td>{currentPage * perPage + key + 1}</td>
                            {/* <td>{key + 1}</td> */}
                            <td>{emp.email}</td>
                            <td>{emp.amount}</td>
                            <td>{emp.interval}</td>
                            <td>
                              {' '}
                              <Moment format="DD MMMM YYYY, dddd">
                                {emp.createdAt || emp.created_at}
                              </Moment>
                            </td>

                            <td>
                              {' '}
                              <Moment format="DD MMMM YYYY, dddd">
                                {emp.updatedAt}
                              </Moment>
                            </td>
                            {/* {emp.status === 'active' ? (
                              <td>ACTIVE</td>
                            ) : (
                              <td>INACTIVE</td>
                            )} */}

                            <td>
                              {/* <Button className="bg-primary mx-2 my-1 ">
                                <i className="ri-edit-box-line"></i>
                              </Button> */}
                              <Button
                                className="bg-danger mx-2 my-1"
                                onClick={() => deletePay(emp._id)}
                              >
                                <i className="ri-delete-bin-6-fill"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
              <Row>
                <Col lg="3">
                  <div className="btn-group mx-4">
                    <select
                      className="bg-secondary text-light"
                      value={perPage}
                      onChange={handleSelect}
                      style={{
                        fontSize: '1.4em',
                        padding: '1.5px',
                      }}
                    >
                      {perPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg="9">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel="..."
                    pageCount={totalPages}
                    onPageChange={(page) => handlePageClick(page.selected + 1)}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'item active '}
                    disableInitialCallback={true}
                    initialPage={currentPage}
                    forcePage={currentPage}
                  />
                </Col>
              </Row>
              {/* <Col lg="3">
                <div className="btn-group mx-4">
                  <select
                    className="bg-secondary text-light"
                    value={perPage}
                    onChange={handleSelect}
                    style={{
                      fontSize: '1.4em',
                      padding: '1.5px',
                    }}
                  >
                    {perPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col lg="12">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel="..."
                  pageCount={totalPages}
                  onPageChange={(page) => handlePageClick(page.selected + 1)}
                  containerClassName={'pagination'}
                  previousLinkClassName={'pagination__link'}
                  nextLinkClassName={'pagination__link'}
                  disabledClassName={'pagination__link--disabled'}
                  activeClassName={'item active '}
                  disableInitialCallback={true}
                  initialPage={currentPage}
                  forcePage={currentPage}
                />
              </Col> */}
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IndexKanban;
