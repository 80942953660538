import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getStates = async () => {
  let token = await getToken();
  let response = await fetch(`${ApiRoute.getStates}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load states: ${response}`);
};

export const addStateAPI = async (data) => {
  let token = await getToken();
  try {
    const response = await fetch(`${ApiRoute.createState}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

export const deleteStateAPI = async (id) => {
  let token = await getToken();
  try {
    const response = await fetch(`${ApiRoute.deleteState}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ id: id }),
    });
    return response.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
export const updateStates = async (id, name, code) => {
  let data = { id, name, code };
  let token = await getToken();
  let response = await fetch(`${ApiRoute.updateState}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update States: ${response}`);
};

export const getStatesById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getStateById}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get States');
};
export const getVendorByStates = async () => {
  let token = await getToken();
  try {
    let response = await fetch(`${ApiRoute.getVendorsByStates}`, {
      method: 'GET',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        Authorization: 'Bearer ' + token,
      },
    });
    response = await response.json();
    if (response) return response;
  } catch (error) {
    throw new Error(error);
  }
};
