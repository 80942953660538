import React, { useState, useRef } from 'react';

import Swal from 'sweetalert2';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getCompleteVendors } from '../../services/VendorService';
import { CSVLink } from 'react-csv';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Export = () => {
  const [dataToDownload, setDataToDownload] = useState([]);
  const csvDownloadRef = useRef(null);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const headers = [
    { label: 'Email', key: 'email' },
    // { label: 'id', key: '_id' },
    { label: 'Business', key: 'business' },
    { label: 'Profile Picture', key: 'pic_path' },
    { label: 'FirstName', key: 'firstName' },
    { label: 'LastName', key: 'lastName' },
    { label: 'Costly', key: 'costly' },
    { label: 'Phone', key: 'phone' },
    { label: 'isPaid', key: 'isPaid' },
    { label: 'Views', key: 'views' },
    { label: 'Pick', key: 'pick' },
    { label: 'Position', key: 'position' },
    { label: 'Willing to Travel', key: 'willingToTravel' },
    { label: 'Website', key: 'website' },
    { label: 'Category', key: 'categories' },
    { label: 'State', key: 'states' },
  ];

  const fetchVendorData = async () => {
    try {
      setIsLoading(true);
      const response = await getCompleteVendors();
      // const vendorData = await response.data;
      const vendorData = await response.data.map((vendor) => ({
        ...vendor,
        categories: vendor.categories
          .map((category) => category.title)
          .join(', '), // convert categories to a string of titles separated by commas
        states: vendor.states.map((state) => state.name).join(', '), // convert categories to a string of titles separated by commas
      }));

      setDataToDownload(vendorData);

      csvDownloadRef.current.link.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Export " breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col lg="12">
              <Col sm="12"></Col>
              <Card>
                <CardBody>
                  <div className="col-md-2">
                    {/* <CSVLink
                      data={data}
                      headers={headers}
                      filename="VendorsData.csv"
                    > */}
                    <CSVLink
                      data={dataToDownload}
                      headers={headers}
                      filename="VendorData.csv"
                      target="_blank"
                      ref={csvDownloadRef}
                    />
                    <button
                      onClick={fetchVendorData}
                      className="btn btn-primary mb-2"
                    >
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                      ) : (
                        <span> EXPORT VENDORS DATA</span>
                      )}
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Export;

// import React, { useState, useRef } from 'react';

// import Swal from 'sweetalert2';
// import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { getCompleteVendors } from '../../services/VendorService';
// import { CSVLink } from 'react-csv';
// import Breadcrumbs from '../../components/Common/Breadcrumb';

// const Export = () => {
//   const [dataToDownload, setDataToDownload] = useState([]);
//   const csvDownloadRef = useRef(null);
//   const [breadcrumbItems, setBreadcrumbItems] = useState([]);

//   const headers = [
//     { label: 'Email', key: 'email' },
//     // { label: 'id', key: '_id' },
//     { label: 'Business', key: 'business' },
//     { label: 'Profile Picture', key: 'pic_path' },
//     { label: 'FirstName', key: 'firstName' },
//     { label: 'LastName', key: 'lastName' },
//     { label: 'Costly', key: 'costly' },
//     { label: 'Phone', key: 'phone' },
//     { label: 'isPaid', key: 'isPaid' },
//     { label: 'Views', key: 'views' },
//     { label: 'Pick', key: 'pick' },
//     { label: 'Position', key: 'position' },
//     { label: 'Willing to Travel', key: 'willingToTravel' },
//     { label: 'Website', key: 'website' },
//   ];
//   const fetchVendorData = async () => {
//     try {
//       const response = await getCompleteVendors();
//       const vendorData = await response.data;

//       setDataToDownload(vendorData);

//       csvDownloadRef.current.link.click();
//     } catch (error) {
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         title: 'SERVER ERROR',
//         showConfirmButton: false,
//       });
//     }
//   };
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs title="Export " breadcrumbItems={breadcrumbItems} />
//           <Row>
//             <Col lg="12">
//               <Col sm="12"></Col>
//               <Card>
//                 <CardBody>
//                   <div className="col-md-2">
//                     {/* <CSVLink
//                       data={data}
//                       headers={headers}
//                       filename="VendorsData.csv"
//                     > */}
//                     <CSVLink
//                       data={dataToDownload}
//                       headers={headers}
//                       filename="VendorData.csv"
//                       target="_blank"
//                       ref={csvDownloadRef}
//                     />
//                     <button
//                       onClick={fetchVendorData}
//                       className="btn btn-primary mb-2"
//                     >
//                       EXPORT VENDORS DATA
//                     </button>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };
// export default Export;
// setTimeout(() => {
//   csvDownloadRef.current.link.click();
// }, 500);

{
  /* <div class="row">
                      <div class="col-md-4">
                        <input
                          type="date"
                          name="start_date"
                          class="form-control mydatepicker"
                          autocomplete="off"
                        />
                      </div>
                      <div class="col-md-4">
                        <input
                          type="date"
                          name="end_date"
                          class="form-control mydatepicker"
                          autocomplete="off"
                        />
                      </div>
                     
                    </div> */
}
{
  /* </CSVLink> */
}
{
  /* <CSVLink
                      data={data}
                      headers={headers}
                      filename="VendorsData.csv"
                    >
                      <button className="btn btn-primary mb-2">EXPORT</button>
                    </CSVLink> */
}
