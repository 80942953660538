import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getAllSubscription = async () => {
  let token = await getToken();
  if (token === null) {
    throw new Error('Token required');
  }
  // console.log('TOKEN', token);
  let response = await fetch(`${ApiRoute.getSubscriptions}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },

    // Authorization: `Bearer ` + token,
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Subscriptions: ${response}`);
};

export const getStripeProducts = async () => {
  let token = await getToken();
  if (token === null) {
    throw new Error('Token required');
  }
  // console.log('TOKEN', token);
  let response = await fetch(`${ApiRoute.getStripeProducts}`, {
    method: 'GET',
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Products: ${response}`);
};

export const getStripePrices = async () => {
  let token = await getToken();
  if (token === null) {
    throw new Error('Token required');
  }
  // console.log('TOKEN', token);
  let response = await fetch(`${ApiRoute.getStripePrices}`, {
    method: 'GET',
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Prices: ${response}`);
};
export const getSubscriptionById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getSingleSubscription}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Subscrption');
};
