// import React, { Component } from 'react';

// // Redux
// import { Link } from 'react-router-dom';

// import {
//   Row,
//   Col,
//   Input,
//   Button,
//   Container,
//   Label,
//   FormGroup,
// } from 'reactstrap';

// // availity-reactstrap-validation
// import { AvForm, AvField } from 'availity-reactstrap-validation';

// // import images
// import logodark from '../../assets/images/logo-dark.png';

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {
//     document.body.classList.add('auth-body-bg');
//   }

//   componentWillUnmount() {
//     document.body.classList.remove('auth-body-bg');
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <div>
//           <Container fluid className="p-0">
//             <Row className="g-0">
//               <Col lg={4}>
//                 <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
//                   <div className="w-100">
//                     <Row className="justify-content-center">
//                       <Col lg={9}>
//                         <div>
//                           <div className="text-center">
//                             <div>
//                               <Link to="/" className="logo">
//                                 <img src={logodark} height="20" alt="logo" />
//                               </Link>
//                             </div>

//                             <h4 className="font-size-18 mt-4">
//                               Welcome Back !
//                             </h4>
//                             <p className="text-muted">
//                               Sign in to continue to Event Massi.
//                             </p>
//                           </div>

//                           <div className="p-2 mt-5">
//                             <AvForm className="form-horizontal">
//                               <FormGroup className="auth-form-group-custom mb-4">
//                                 <i className="ri-user-2-line auti-custom-input-icon"></i>
//                                 <Label htmlFor="username">Email</Label>
//                                 <AvField
//                                   name="username"
//                                   type="text"
//                                   className="form-control"
//                                   id="username"
//                                   validate={{ email: true, required: true }}
//                                   placeholder="Enter username"
//                                 />
//                               </FormGroup>

//                               <FormGroup className="auth-form-group-custom mb-4">
//                                 <i className="ri-lock-2-line auti-custom-input-icon"></i>
//                                 <Label htmlFor="userpassword">Password</Label>
//                                 <AvField
//                                   name="password"
//                                   type="password"
//                                   className="form-control"
//                                   id="userpassword"
//                                   placeholder="Enter password"
//                                 />
//                               </FormGroup>

//                               <div className="form-check">
//                                 <Input
//                                   type="checkbox"
//                                   className="form-check-input"
//                                   id="customControlInline"
//                                 />
//                                 <Label
//                                   className="form-check-label"
//                                   htmlFor="customControlInline"
//                                 >
//                                   Remember me
//                                 </Label>
//                               </div>

//                               <div className="mt-4 text-center">
//                                 <Button
//                                   color="primary"
//                                   className="w-md waves-effect waves-light"
//                                   type="submit"
//                                 >
//                                   Log In
//                                 </Button>
//                               </div>

//                               <div className="mt-4 text-center">
//                                 <Link
//                                   to="/auth-recoverpw"
//                                   className="text-muted"
//                                 >
//                                   <i className="mdi mdi-lock me-1"></i> Forgot
//                                   your password?
//                                 </Link>
//                               </div>
//                             </AvForm>
//                           </div>

//                           <div className="mt-5 text-center">
//                             <p>
//                               Don't have an account ?{' '}
//                               <Link
//                                 to="/auth-register"
//                                 className="fw-medium text-primary"
//                               >
//                                 {' '}
//                                 Register{' '}
//                               </Link>{' '}
//                             </p>
//                             {/* <p>
//                               © 2021 Nazox. Crafted with{' '}
//                               <i className="mdi mdi-heart text-danger"></i> by
//                               Themesdesign
//                             </p> */}
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                 </div>
//               </Col>
//               <Col lg={8}>
//                 <div className="authentication-bg">
//                   <div className="bg-overlay"></div>
//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// export default Login;

import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContent } from '../Authentication/ToastContent';
import { Row, Col, Button, Alert, Container, Label } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { login } from '../../services/AuthService';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.state = { username: 'admin@themesdesign.in', password: '123456' };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleSubmit(event, values) {
  //   this.props.checkLogin(values, this.props.history);
  // }

  componentDidMount() {
    this.props.apiError('');
    document.body.classList.add('auth-body-bg');
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg');
  }

  handleSubmit = async (event) => {
    toast.dismiss();
    event.preventDefault();

    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;

    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (validateEmail && password) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await login(email, password);
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully Login'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });

        localStorage.setItem('authUser', loginData.token);

        this.props.history.push('/dashboard');
      } else {
        toast(() => ToastContent(loginData.error), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent('Please enter valid credentials'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/">
                                <img
                                  src={
                                    'https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg'
                                  }
                                  alt=""
                                  height="50"
                                  className="auth-logo logo-dark mx-auto"
                                />
                                <img
                                  src={
                                    'https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg'
                                  }
                                  alt=""
                                  height="20"
                                  className="auth-logo logo-light mx-auto"
                                />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to Event Massi Admin Dashboard.
                            </p>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  // value={this.state.username}
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter username"
                                />
                              </div>

                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  // value={this.state.password}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>© 2023 Event Massi Admin</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
