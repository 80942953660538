import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getallFaqs = async () => {
  let token = await getToken();
  try {
    let response = await fetch(`${ApiRoute.getFaqs}`, {
      method: 'GET',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        Authorization: 'Bearer ' + token,
      },
    });
    response = await response.json();
    if (response) return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const postFaqs = async (description, title) => {
  let token = await getToken();
  let data = { title, description };
  let response = await fetch(`${ApiRoute.postFaq}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not post Faqs: ${response}`);
};

export const updateFaqs = async (id, title, description) => {
  let token = await getToken();
  let data = { id, title, description };
  let response = await fetch(`${ApiRoute.updateFaq}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Faqs: ${response}`);
};

export const getFaqsById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getFaqById}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get FAQS');
};
