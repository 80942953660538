import React, { Component, useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import SocialMedia from './SocialMedia';
import VendorInformation from './VendorInformation';
import { getVendorById } from '../../services/VendorService';
import VendorProfileImage from './VendorProfileImage';
import VendorMessages from './VendorMessages';
import DealsInformation from './DealsInformation';
import { Blocks } from 'react-loader-spinner';
import { ApiRoute } from '../../util/constants';
import VendorImages from './VendorImages';
import './Gall.css';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Label,
  NavItem,
  NavLink,
  Progress,
  Input,
  Form,
  Container,
} from 'reactstrap';
import './Socialmedia.css';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { updateVendor } from '../../services/VendorService';
import Pricing from '../Utility/Pricing';
import { getallCategories } from '../../services/CategoriesService';
import { getStates } from '../../services/StateService';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const FormWizard = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: 'Vendor Management',
      link: '#',
    },
    {
      title: 'Vendors',
      link: '#',
    },
  ]);

  const [post, SetPost] = useState({});
  const [address, setAddress] = useState([]);
  const [business, setBusiness] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategoriesOpts, setSelectedCategoriesOpts] = useState(null);
  const [assets, setAssets] = useState([]);
  const [contact, setContact] = useState([]);
  const [deals, setDeals] = useState([]);
  const [payment, setPayment] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [willingToTravel, setWillingToTravel] = useState(true);
  const [activeTabProgress, setActiveTabProgress] = useState(1);
  // const [vendor, setVendor] = useState([]);
  // const [vendor2, setVendor2] = useState([]);
  // const [vendor3, setVendor3] = useState([]);
  const [progressValue, setProgressValue] = useState(25);

  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryKeywords, setCategoryKeywords] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const handleCategoryChange = async (handleCategoryChange) => {
    let categoryData = handleCategoryChange;
    console.log('====================================');
    console.log(categoryData, 'Category ');
    console.log('====================================');
    setSelectedCategories(categoryData);
    if (categoryData.length > 0) {
      let categoryIds = categoryData
        .map((category) => category.value)
        .join('&categoryId=');
      const getCategoryKeywordsUrl = ApiRoute.getCategoryKeywords(categoryIds);
      fetch(getCategoryKeywordsUrl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log('RESULT', result);
          setCategoryKeywords(result);
        });
    } else {
      setCategoryKeywords([]);
    }
  };

  const handleStateChange = async (handleStateChange) => {
    setSelectedStates(handleStateChange);
  };
  const handleKeywordChange = (handleKeywordChange) => {
    setSelectedKeywords(handleKeywordChange);
  };
  const handleChange = (event) => {
    setWillingToTravel(event.target.checked);
    console.log(event.target.checked);
  };
  const fetchCategoryData = async () => {
    try {
      const response = await getallCategories();
      const data = await response.data;
      setAllCategories(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchStateData = async () => {
    try {
      const response = await getStates();
      const data = await response.data;
      setAllStates(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const stateOptions = allStates.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const categoriesOption = allCategories?.map(({ id, title }) => ({
    value: id,
    label: title,
  }));

  let { id } = props.match.params;
  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await getVendorById(id);

        setPayment(response.payment);
        setDeals(response.deals);
        setContact(response.contact);
        SetPost(response.data);
        setBusiness(response.data.business);
        setAddress(response.data.address);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setPhone(response.data.phone);
        let selectedCategories = response.data.categories.map(
          ({ id, title }) => ({
            value: id,
            label: title,
          })
        );
        setSelectedCategoriesOpts(selectedCategories);
        handleCategoryChange(selectedCategories);
        setCategories(response.data.categories);
        setStates(response.data.states);
        setKeywords(response.data.keywords);
        setAssets(response.data.assets);
        setWillingToTravel(response.data.willingToTravel);
        console.log(response.data.willingToTravel);
        setIsLoading(false);
        const selectedKeywords = response.data.keywords.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        await setSelectedKeywords(selectedKeywords);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    fetchCategoryData();
    fetchStateData();
  }, []);
  const selectedStatesOpts = states.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const selectedKeywordsOpts = keywords.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  async function updateByID(e) {
    e.preventDefault();

    try {
      let keyword = selectedKeywords.map(({ value }) => value);
      // if (keyword.length === 0 && keywords.length > 0) {
      //   keyword = keywords.map(({ id }) => id);
      // }
      let state = selectedStates.map(({ value }) => value);
      if (state.length === 0 && states.length > 0) {
        state = states.map(({ id }) => id);
      }
      let category = selectedCategories.map(({ value }) => value);
      // if (category.length === 0 && categories.length > 0) {
      //   category = categories.map(({ id }) => id);
      // }
      var result = await updateVendor(
        id,
        address,
        business,
        firstName,
        lastName,
        phone,
        category,
        keyword,

        willingToTravel,
        state
      );
      if (result?.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Updated',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const toggleTabProgress = (tab) => {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTabProgress(tab);

        if (tab === 1) {
          setProgressValue(20);
        }

        if (tab === 2) {
          setProgressValue(40);
        }

        if (tab === 3) {
          setProgressValue(60);
        }

        if (tab === 4) {
          setProgressValue(80);
        }
        if (tab === 5) {
          setProgressValue(100);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '200',
            }}
          >
            <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
          </div>
        ) : (
          <Container fluid={true}>
            <Breadcrumbs title="Vendors" breadcrumbItems={breadcrumbItems} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">View And Edit Vendor</h4>

                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTabProgress === 1,
                            })}
                            onClick={() => {
                              toggleTabProgress(1);
                            }}
                          >
                            <span className="step-number"></span>
                            <span className="step-title">Vendor Details</span>
                          </NavLink>
                        </NavItem>
                        {contact.length ? (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTabProgress === 2,
                              })}
                              onClick={() => {
                                toggleTabProgress(2);
                              }}
                            >
                              <span className="step-number"></span>
                              <span className="step-title">Messages</span>
                            </NavLink>
                          </NavItem>
                        ) : null}
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTabProgress === 2,
                            })}
                            onClick={() => {
                              toggleTabProgress(2);
                            }}
                          >
                            <span className="step-number"></span>
                            <span className="step-title">Messages</span>
                          </NavLink>
                        </NavItem> */}
                        {/* {assets.length ? ( */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTabProgress === 3,
                            })}
                            onClick={() => {
                              toggleTabProgress(3);
                            }}
                          >
                            <span className="step-number"></span>
                            <span className="step-title">Gallery</span>
                          </NavLink>
                        </NavItem>
                        {/* ) : null} */}
                        {/* {payment !== null ? ( */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTabProgress === 4,
                            })}
                            onClick={() => {
                              toggleTabProgress(4);
                            }}
                          >
                            <span className="step-number"></span>
                            <span className="step-title">Subscription</span>
                          </NavLink>
                        </NavItem>
                        {/* ) : null} */}
                        {deals.length ? (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTabProgress === 5,
                              })}
                              onClick={() => {
                                toggleTabProgress(5);
                              }}
                            >
                              <span className="step-number"></span>
                              <span className="step-title">
                                Deals And Specials
                              </span>
                            </NavLink>
                          </NavItem>
                        ) : null}
                      </ul>

                      {/* <div id="bar" className="mt-4">
                     <Progress
                       color="success"
                       striped
                       animated
                       value={this.state.progressValue}
                     />
                    </div> */}
                      <TabContent
                        activeTab={activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Card>
                            <CardBody>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-firstname-input14"
                                      >
                                        First Name
                                      </Label>
                                      <Input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => {
                                          setFirstName(e.target.value);
                                        }}
                                        // defaultValue={
                                        //   post.first_name || post.firstName
                                        // }
                                        className="form-control"
                                        id="basicpill-firstname-input14"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-lastname-input15"
                                      >
                                        Last Name
                                      </Label>
                                      <Input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => {
                                          setLastName(e.target.value);
                                        }}
                                        // defaultValue={
                                        //   post.Last_name || post.LastName
                                        // }
                                        className="form-control"
                                        id="basicpill-lastname-input15"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-phoneno-input16"
                                      >
                                        Email Address
                                      </Label>
                                      <Input
                                        type="email"
                                        disabled
                                        defaultValue={post.email}
                                        className="form-control"
                                        id="basicpill-phoneno-input16"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-email-input17"
                                      >
                                        Phone Number
                                      </Label>
                                      <Input
                                        type="number"
                                        value={phone}
                                        onChange={(e) => {
                                          setPhone(e.target.value);
                                        }}
                                        // defaultValue={post.phone}
                                        className="form-control"
                                        id="basicpill-email-input17"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-address-input2"
                                      >
                                        Vendor Name
                                      </Label>
                                      <Input
                                        type="text"
                                        value={business}
                                        onChange={(e) => {
                                          setBusiness(e.target.value);
                                        }}
                                        // defaultValue={post.business}
                                        className="form-control"
                                        id="basicpill-lastname-input15"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-address-input2"
                                      >
                                        State
                                      </Label>

                                      <Select
                                        id="states"
                                        placeholder="Please select state"
                                        options={stateOptions}
                                        isMulti={true}
                                        name="select_state"
                                        isDisabled={
                                          stateOptions.length === 0
                                            ? true
                                            : false
                                        }
                                        onChange={handleStateChange}
                                        required
                                        defaultValue={selectedStatesOpts}
                                        className="vendorprofile"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-address-input2"
                                      >
                                        Vendor Category
                                      </Label>
                                      <Select
                                        defaultValue={selectedCategoriesOpts}
                                        isMulti={true}
                                        id="categories"
                                        placeholder="Please select category"
                                        options={categoriesOption}
                                        isDisabled={
                                          categoriesOption.length === 0
                                            ? true
                                            : false
                                        }
                                        onChange={handleCategoryChange}
                                        name="select_category"
                                        className="vendorprofile"
                                        required
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-address-input2"
                                      >
                                        Vendor Keywords
                                      </Label>
                                      <Select
                                        name="select_keyword"
                                        className="vendorprofile"
                                        placeholder={'vendor Keywords'}
                                        options={categoryKeywords.map(
                                          (item) => ({
                                            value: item.id,
                                            label: item.name,
                                          })
                                        )}
                                        onChange={handleKeywordChange}
                                        defaultValue={selectedKeywordsOpts}
                                        isMulti={true}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mt-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-email-input17"
                                      >
                                        Willing to Travel
                                      </Label>
                                      <Input
                                        type="checkbox"
                                        checked={willingToTravel}
                                        onChange={handleChange}
                                        className="form-control"
                                        id="basicpill-email-input17"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-address-input2"
                                      >
                                        Address & City
                                      </Label>
                                      <textarea
                                        // placeholder={post.address}
                                        // defaultValue={post.address}
                                        value={address}
                                        onChange={(e) => {
                                          setAddress(e.target.value);
                                        }}
                                        id="basicpill-address-input2"
                                        className="form-control"
                                        rows="2"
                                      ></textarea>
                                    </div>
                                  </Col>
                                </Row>

                                {/* {post.pic_path ? (
                            <img
                              className="mx-auto d-flex"
                              height={200}
                              width={'auto'}
                              src={
                                'https://massi-bucket.s3.amazonaws.com/' +
                                post.pic_path
                              }
                              alt="Slider"
                            ></img>
                          ) : (
                            <img
                              className="mx-auto d-flex"
                              height={80}
                              width={'auto'}
                              src={
                                'https://eventmassi.idemos.website/images/Massi%20Header%20Logo.svg' +
                                post.pic_path
                              }
                              alt="Slider"
                            ></img>
                          )} */}
                                <div className="customFormGrp btnFormGrp mt-3 mx-auto justify-content-center d-flex">
                                  <button
                                    onClick={updateByID}
                                    type="submit"
                                    className="editProfileSubmitBtn  rounded-4 fs-5 col-lg-3 col-12 btn btn-dark mx-1"
                                  >
                                    Update
                                  </button>
                                </div>

                                {/* <button
                            onClick={updateByID}
                            type="submit"
                            class="btn btn-success mx-1"
                          >
                            Update
                          </button> */}
                              </Form>
                            </CardBody>
                          </Card>
                          <VendorInformation id={id} />
                          <VendorProfileImage id={id} />
                          <SocialMedia id={id} />
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            {/* <div class="row">
                              <div class="col-lg-3 col-md-6">
                                {post.views ? (
                                  <div class="card">
                                    <div class="card-body">
                                      <div class="d-flex p-10 no-block">
                                        <div class="align-slef-center">
                                          <div>
                                            <h2 class="m-b-0"> {post.views}</h2>
                                            <h6 class="text-muted m-b-0">
                                              Vendor Visits
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="align-self-center display-6 ml-auto">
                                          <i class="text-success icon-Target-Market"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div> */}

                            <div>
                              {/* <h4>Vendor Messages</h4> */}
                              <VendorMessages id={id} />
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            {/* <div class="row">
                              <div class="col-lg-3 col-md-6">
                                {post.views ? (
                                  <div class="card">
                                    <div class="card-body">
                                      <div class="d-flex p-10 no-block">
                                        <div class="align-slef-center">
                                          <div>
                                            <h2 class="m-b-0"> {post.views}</h2>
                                            <h6 class="text-muted m-b-0">
                                              Vendor Visits
                                            </h6>
                                          </div>
                                        </div>
                                        <div class="align-self-center display-6 ml-auto">
                                          <i class="text-success icon-Target-Market"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div> */}

                            <div>
                              <h4>Vendor Photos</h4>
                              <VendorImages id={id} />

                              {/* {assets.map((user) => (
                            <div>
                              {user.path ? (
                                <img
                                  className="mx-auto d-flex"
                                  height={300}
                                  width={'auto'}
                                  src={
                                    'https://massi-bucket.s3.amazonaws.com/' +
                                    user.path
                                  }
                                  alt="Slider"
                                ></img>
                              ) : user.url ? (
                                <img
                                  className="mx-auto d-flex"
                                  height={300}
                                  width={'auto'}
                                  src={
                                    'https://massi-bucket.s3.amazonaws.com/' +
                                    user.url
                                  }
                                  alt="Slider"
                                ></img>
                              ) : null}
                            </div>
                          ))} */}
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId={4}>
                          <div>
                            <Pricing id={id} />
                          </div>
                        </TabPane>
                        <TabPane tabId={5}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <DealsInformation id={id} />
                              {/* <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                            </div>
                            <div>
                              <h5>Deals</h5>
                            </div>
                          </div> */}
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      {/* <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTabProgress === 1
                            ? 'previous disabled'
                            : 'previous'
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTabProgress(activeTabProgress - 1);
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={
                          activeTabProgress === 4 ? 'next disabled' : 'next'
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTabProgress(activeTabProgress + 1);
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default FormWizard;
