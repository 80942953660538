import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getAllSliders = async () => {
  let token = await getToken();
  let response = await fetch(`${ApiRoute.getSliders}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Sliders: ${response}`);
};

export const getSliderById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getSliderById}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Slider');
};
export const updateSlider = async (id, imagePath) => {
  let token = await getToken();
  let data = { id, imagePath };
  let response = await fetch(`${ApiRoute.updateSlider}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Slider: ${response}`);
};
