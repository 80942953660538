import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { ToastContent } from './ToastContent';
import { Row, Col, Button, Alert, Container, Label } from 'reactstrap';
import EmLogo from '../../assets/images/eventmassi-logo.svg';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { login } from '../../services/AuthService';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleSubmit(event, values) {
  //   this.props.checkLogin(values, this.props.history);
  // }

  componentDidMount() {
    this.props.apiError('');
    document.body.classList.add('auth-body-bg');
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg');
  }

  handleSubmit = async (event) => {
    toast.dismiss();
    event.preventDefault();

    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;

    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (validateEmail && password) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await login(email, password);
      toast.dismiss();
      if (loginData.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'LOGGED IN',
          showConfirmButton: false,
          timer: 2000,
        });
        // toast(() => ToastContent('Successfully Login'), {
        //   toastId: 'successToast',
        //   hideProgressBar: false,
        //   type: toast.TYPE.SUCCESS,
        //   autoClose: 500,
        // });

        localStorage.setItem('authUser', loginData.token);
        localStorage.setItem('userName', loginData.data.admin.firstName);
        this.props.history.push('/dashboard');
      } else {
        toast(() => ToastContent(loginData.error), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent('Please enter valid credentials'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/">
                                <img
                                  // src={
                                  //   'https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg'
                                  // }
                                  src={EmLogo}
                                  alt=""
                                  height="100"
                                  className="auth-logo logo-dark mx-auto"
                                />
                                <img
                                  // src={
                                  //   'https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg'
                                  // }
                                  src={EmLogo}
                                  alt=""
                                  height="100"
                                  className="auth-logo logo-light mx-auto"
                                />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to Event Massi Admin Dashboard.
                            </p>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  // value={this.state.username}
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  id="username"
                                  // validate={{ email: true, required: true }}
                                  placeholder="Enter Email"
                                />
                              </div>

                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  // value={this.state.password}

                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>© 2023 Event Massi Admin</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
