import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getBrowseVendorList = async (page, limit, search = null) => {
  let token = await getToken();
  var url = `${ApiRoute.getVendors}`;
  if (page != null) url += `?page=${page}`;
  if (limit != null) url += `?limit=${limit}`;
  if (search != null) url += search;

  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get vendor list');
};
export const browseVendors = async (page, limit, search = null) => {
  let token = await getToken();
  var url = `${ApiRoute.browseVendors}`;
  if (page != null) url += `?page=${page}`;
  if (limit != null) url += `?limit=${limit}`;
  if (search != null) url += search;

  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get browsevendor list');
};

export const deleteVendors = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.deleteVendor}`;
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      // body: JSON.stringify({ id: id }),
    });
    return response.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
export const getVendorById = async (id) => {
  let token = await getToken();
  const getVendorByIdUrl = ApiRoute.getVendorById(id);
  let response = await fetch(getVendorByIdUrl, {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Vendor');
};

export const updateVendor = async (
  id,
  address,
  business,
  firstName,
  lastName,
  phone,
  categories,
  keywords,
  willingToTravel,
  states
) => {
  let token = await getToken();
  let data = {
    id,
    address,
    business,
    firstName,
    lastName,
    phone,
    categories,
    keywords,
    willingToTravel,
    states,
  };
  let response = await fetch(`${ApiRoute.updateVendorProfile}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Vendor: ${response}`);
};
export const updateSocial = async (
  id,
  facebook,
  instagram,
  pinterest,
  tiktok,
  twitter,
  youtube,
  website
) => {
  let token = await getToken();
  let data = {
    id,
    socials: {
      facebook,
      instagram,
      pinterest,
      tiktok,
      twitter,
      youtube,
      website,
    },
  };
  let response = await fetch(`${ApiRoute.updateVendorProfile}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Vendor: ${response}`);
};
export const updateInfo = async (id, costly, description, keywords) => {
  let token = await getToken();
  let data = {
    id,
    costly,
    description,
    keywords,
  };
  let response = await fetch(`${ApiRoute.updateVendorProfile}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Vendor: ${response}`);
};

export const updateImage = async (id, imagePath) => {
  let token = await getToken();
  let data = { id, imagePath };
  let response = await fetch(`${ApiRoute.updateVendorProfile2}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Slider: ${response}`);
};
export const updatePick = async (id, pick) => {
  let token = await getToken();
  let data = { id, pick };
  let response = await fetch(`${ApiRoute.assignPosition}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Pick: ${response}`);
};
export const topVendors = async () => {
  let token = await getToken();
  var url = `${ApiRoute.getTopVendorsWithPositions}`;

  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get  list');
};

export const getContacts = async () => {
  let token = await getToken();
  var url = `${ApiRoute.getContacts}`;

  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Contact us users');
};
export const getAllVendors = async () => {
  let token = await getToken();
  let response = await fetch(`${ApiRoute.getVendors2}`, {
    method: 'GET',
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Vendors: ${response}`);
};

export const getCompleteVendors = async () => {
  let token = await getToken();
  let response = await fetch(`${ApiRoute.getAllusersToSheet}`, {
    method: 'GET',
    headers: {
      ...CONSTANTS.REQUEST_HEADERS,
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Vendors: ${response}`);
};
// export const deletePick = async () => {
//   let token = await getToken();
//   let response = await fetch(
//     `https://api.eventmassi.com/api/v2/admin/delete-position`,
//     {
//       method: 'DELETE',
//       headers: {
//         ...CONSTANTS.REQUEST_HEADERS,
//         Authorization: 'Bearer ' + token,
//       },
//     }
//   );
//   response = await response.json();
//   if (response) return response;
//   throw new Error(`Could not delete Pick: ${response}`);
// };
