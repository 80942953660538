import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from './MiniWidgets';

import SalesAnalytics from './SalesAnalytics';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      breadcrumbItems: [
        { title: 'Event Massi', link: '/' },
        { title: 'Dashboard', link: '#' },
      ],

      reports: [
        {
          icon: 'ri-shield-user-fill',
          title: 'Total Vendors ',
          // value: '1452',
          // rate: '2.4%',
          // desc: 'From previous period',
        },
        {
          icon: 'ri-admin-fill',
          title: 'Admins',
          // value: '$ 38452',
          // rate: '2.4%',
          // desc: 'From previous period',
        },
        {
          icon: 'ri-question-answer-fill',
          title: 'Total Inquiries',
          // value: '$ 15.4',
          // rate: '2.4%',
          // desc: 'From previous period',
        },
        {
          icon: 'ri-group-fill',
          title: 'Preferred Vendors',
          // value: '$ 15.4',
          // rate: '2.4%',
          // desc: 'From previous period',
        },
        {
          icon: 'ri-group-2-fill',
          title: 'Non-Preferred Vendors',
          // value: '$ 15.4',
          // rate: '2.4%',
          // desc: 'From previous period',
        },
      ],
    };
  }
  // async componentDidMount() {
  //   const response = await getBrowseVendorList();
  //   const data = await response.data.total;
  //   console.log(data, 'DATA');
  //   this.setState({ data });
  // }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row>
                  {/* <MiniWidgets reports={this.state.reports} /> */}
                  <MiniWidgets />
                </Row>

                {/* revenue Analytics */}
                {/* <RevenueAnalytics /> */}
              </Col>

              <Col xl={12}>
                {/* sales Analytics */}
                {/* <SalesAnalytics /> */}

                {/* earning reports */}
                {/* <EarningReports /> */}
              </Col>
            </Row>

            <Row>
              {/* sources */}
              {/* <Sources /> */}

              {/* recent activity */}
              {/* <RecentlyActivity /> */}

              {/* revenue by locations */}
              {/* <RevenueByLocations /> */}
            </Row>

            <Row>
              {/* chat box */}
              {/* <ChatBox /> */}

              {/* latest transactions */}
              {/* <LatestTransactions /> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
