import React from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { useState, useEffect } from 'react';
import './Socialmedia.css';
import { getVendorById } from '../../services/VendorService';
import { updateSocial } from '../../services/VendorService';

import Swal from 'sweetalert2';
const SocialMedia = (props) => {
  const [facebook, setFacebook] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [pinterest, setPinterest] = useState([]);
  const [tiktok, setTiktok] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [website, setWebsite] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchVendorData = async () => {
      try {
        const response = await getVendorById(props.id);
        let facebookData = response.data.socials?.facebook;
        let instagramData = response.data.socials?.instagram;
        let pinterestData = response.data.socials?.pinterest;
        let tiktokData = response.data.socials?.tiktok;
        let twitterData = response.data.socials?.twitter;
        let youtubeData = response.data.socials?.youtube;
        let websiteData = response.data.socials?.website;

        if (isMounted) {
          setFacebook(facebookData);
          setInstagram(instagramData);
          setPinterest(pinterestData);
          setTiktok(tiktokData);
          setTwitter(twitterData);
          setYoutube(youtubeData);
          setWebsite(websiteData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchVendorData();

    return () => {
      isMounted = false;
    };
  }, [props.id]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     try {
  //       const response = await getVendorById(props.id);
  //       let facebookData = response.data.socials?.facebook;
  //       let instagramData = response.data.socials?.instagram;
  //       let pinterestData = response.data.socials?.pinterest;
  //       let tiktokData = response.data.socials?.tiktok;
  //       let twitterData = response.data.socials?.twitter;
  //       let youtubeData = response.data.socials?.youtube;
  //       let websiteData = response.data.socials?.website;
  //       setFacebook(facebookData);
  //       setInstagram(instagramData);
  //       setPinterest(pinterestData);
  //       setTiktok(tiktokData);
  //       setTwitter(twitterData);
  //       setYoutube(youtubeData);
  //       setWebsite(websiteData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetch();
  // }, []);

  async function updateByID(e) {
    e.preventDefault();

    try {
      var result = await updateSocial(
        props.id,
        facebook,
        instagram,
        pinterest,
        tiktok,
        twitter,
        youtube,
        website
      );
      if (result?.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Updated',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'ERROR',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          {/* <Col lg="12"> */}
          <>
            <>
              <div className="editProfileContentWrap ">
                <div className="dashboard-list-box ">
                  <form className="generalFormWrap myInformationForm editMyInfo">
                    <h3 className="editFormHeading">
                      My Social media profiles
                    </h3>
                    <div className="customFormGrp">
                      <label>
                        Facebook Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/fb-icon.2329df88.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          value={facebook}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setFacebook('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.facebook.com/' + value;
                            }
                            // else if (value === 'https://')
                            else if (
                              value === 'https://' ||
                              value === 'https://www.facebook.com/'
                            ) {
                              value = '';
                            }
                            setFacebook(value);
                          }}
                          // onChange={(e) => {
                          //   setFacebook(e.target.value);
                          // }}
                          type="text"
                          placeholder={facebook}
                          className="customFormControl"
                          id="facebook"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Instagram Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/instagram-icon.80c6cd1f.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={instagram}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setInstagram('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.instagram.com/' + value;
                            }
                            // else if (value === 'https://')
                            else if (
                              value === 'https://' ||
                              value === 'https://www.instagram.com/'
                            ) {
                              value = '';
                            }
                            setInstagram(value);
                          }}
                          // onChange={(e) => {
                          //   setInstagram(e.target.value);
                          // }}
                          placeholder={instagram}
                          className="customFormControl"
                          id="instagram"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Pinterest Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/pinterest-icon.8a4c7cef.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={pinterest}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setPinterest('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.pinterest.com/' + value;
                            } else if (
                              value === 'https://' ||
                              value === 'https://www.pinterest.com/'
                            ) {
                              // else if (value === 'https://')
                              value = '';
                            }
                            setPinterest(value);
                          }}
                          // onChange={(e) => {
                          //   setPinterest(e.target.value);
                          // }}
                          placeholder={pinterest}
                          className="customFormControl"
                          id="pinterest"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Youtube Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/youtube-icon.63de6ca3.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={youtube}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setYoutube('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.youtube.com/' + value;
                            }
                            // else if (value === 'https://')
                            else if (
                              value === 'https://' ||
                              value === 'https://www.youtube.com/'
                            ) {
                              value = '';
                            }
                            setYoutube(value);
                          }}
                          // onChange={(e) => {
                          //   setYoutube(e.target.value);
                          // }}
                          placeholder={youtube}
                          className="customFormControl"
                          id="youtube"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Tiktok Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/tiktok-icon.85d21fee.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={tiktok}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setTiktok('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.tiktok.com/' + value;
                            }
                            // else if (value === 'https://')
                            else if (
                              value === 'https://' ||
                              value === 'https://www.tiktok.com/'
                            ) {
                              value = '';
                            }
                            setTiktok(value);
                          }}
                          placeholder={tiktok}
                          className="customFormControl"
                          id="tiktok"
                        />
                      </div>

                      {/* <div className="formControlWrap">
                        <input
                          type="text"
                          value={tiktok}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              value = 'https://' + value;
                            }
                            setTiktok(value || '');
                          }}
                          // onChange={(e) => {
                          //   setTiktok(e.target.value);
                          // }}
                          placeholder={tiktok}
                          className="customFormControl"
                          id="tiktok"
                        />
                      </div> */}
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Twitter Username{' '}
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/twitter-icon.394ab5a8.svg"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={twitter}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setTwitter('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              // value = 'https://' + value;
                              value = 'https://www.twitter.com/' + value;
                            }
                            // else if (value === 'https://')
                            else if (
                              value === 'https://' ||
                              value === 'https://www.twitter.com/'
                            ) {
                              value = '';
                            }
                            setTwitter(value);
                          }}
                          // onChange={(e) => {
                          //   setTwitter(e.target.value);
                          // }}
                          placeholder={twitter}
                          className="customFormControl"
                          id="twitter"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp">
                      <label>
                        Website{' '}
                        <img
                          src="https://seeklogo.com/images/W/web-icon-logo-A6B586D114-seeklogo.com.png"
                          alt=""
                        />
                      </label>
                      <div className="formControlWrap">
                        <input
                          type="text"
                          value={website}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (!value) {
                              setWebsite('');
                              return;
                            }
                            if (
                              !value.startsWith('http://') &&
                              !value.startsWith('https://')
                            ) {
                              value = 'https://' + value;
                            } else if (value === 'https://') {
                              value = '';
                            }
                            setWebsite(value);
                          }}
                          // onChange={(e) => {
                          //   setWebsite(e.target.value);
                          // }}
                          placeholder={website}
                          className="customFormControl"
                          id="website"
                        />
                      </div>
                    </div>
                    <div className="customFormGrp btnFormGrp">
                      <button
                        onClick={updateByID}
                        className="editProfileSubmitBtn col-lg-3 col-12  btn btn-success mx-1"
                      >
                        Update Social Media
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          </>
          {/* </Col> */}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default SocialMedia;
