import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import AuthLockScreen from '../pages/Authentication/AuthLockScreen';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

//Ecommerce Pages

import Category from '../pages/Ecommerce/Category/index';

import Faq from '../pages/Ecommerce/Faqs';
import Contact from '../pages/Ecommerce/Contact';
import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout';

import Sliders from '../pages/Ecommerce/Sliders';
import Subscriber from '../pages/Ecommerce/Subscriber';
import Subscription from '../pages/Ecommerce/Subscription';
import Export from '../pages/Ecommerce/Export';
import Position from '../pages/Ecommerce/Position';
import Vendor from '../pages/Ecommerce/Vendor';

//ADDING DATA

//Email
import EmailInbox from '../pages/Email/email-inbox';
import EmailRead from '../pages/Email/email-read';

//Utility

import Pricing from '../pages/Utility/Pricing';

// Forms

import FormWizard from '../pages/Forms/FormWizard';

// Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';
import Register1 from '../pages/AuthenticationInner/Register';
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';

//Kanban Board
import KanbanBoard from '../pages/KanbanBoard/index';

const authProtectedRoutes = [
  //Kanban Board
  { path: '/payments', component: KanbanBoard },

  // Forms

  // { path: '/form-wizard', component: FormWizard },
  { path: '/form-wizard/:id', component: FormWizard },

  //Utility

  { path: '/pricing', component: Pricing },

  //Email
  { path: '/admins', component: EmailInbox },
  { path: '/keywords', component: EmailRead },

  //Ecommerce

  { path: '/categories', component: Category },
  { path: '/faqs', component: Faq },
  { path: '/sliders', component: Sliders },
  { path: '/subscribers', component: Subscriber },
  { path: '/subscription', component: Subscription },
  { path: '/export', component: Export },
  { path: '/position', component: Position },
  { path: '/vendor', component: Vendor },
  { path: '/states', component: EcommerceCheckout },
  { path: '/contact', component: Contact },

  { path: '/dashboard', component: Dashboard },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/lock-screen', component: AuthLockScreen },

  // Authentication Inner
  { path: '/auth-login', component: Login1 },
  { path: '/auth-register', component: Register1 },
  { path: '/auth-recoverpw', component: ForgetPwd1 },
];

export { authProtectedRoutes, publicRoutes };
