import React, { useState, useEffect } from 'react';
import {
  getAllSubscription,
  getStripeProducts,
  getStripePrices,
  getSubscriptionById,
} from '../../services/SubscriptionService';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Blocks } from 'react-loader-spinner';

import Swal from 'sweetalert2';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getToken } from '../../services/AuthService';
import { ApiRoute } from '../../util/constants';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Subscription = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [business, setBusiness] = useState([]);
  const [description, setDescription] = useState([]);
  const [descriptions, setDescriptions] = useState(['']);
  const [stripeProduct, setStripeProduct] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [matchedPrice, setMatchedPrice] = useState(null);
  const [packagePrice, setPackagePrice] = useState([]);
  const [productId, setProductId] = useState([]);
  const [valid, setValid] = useState(false);
  const [mode, setMode] = useState('add');
  const [selectedId, setSelectedId] = useState('');
  const openModalForAdd = () => {
    setMode('add');
    setIsOpen(true);
  };

  // when opening the modal for update

  const handleAddField = () => {
    setDescriptions([...descriptions, '']);
  };

  const handleRemoveField = (index) => {
    if (descriptions.length > 1) {
      const updatedDescriptions = [...descriptions];
      updatedDescriptions.splice(index, 1);
      setDescriptions(updatedDescriptions);
    }
  };

  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Subscriptions', link: '#' },
  ]);

  const fetchSubscriptionData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllSubscription();
      const data = await response.data;

      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);
      setTotalPages(pages);
      setData(data);
      setSubscriptionData(
        data.map((item) => ({ id: item.product_id, label: item.name }))
      );
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    const fetchStripeProducts = async () => {
      try {
        setIsLoading(true);
        const response = await getStripeProducts();
        const data = await response.data.data;
        setStripeProduct(
          data.map((item) => ({ id: item.id, label: item.name }))
        );
        setIsLoading(false);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchStripeProducts();
  }, []);
  const options = stripeProduct.filter((product) => {
    const match = subscriptionData.find((sub) => sub.id === product.id);
    // if (match) {
    //   console.log('DATA COMING FROM MONGDB SUBSCRIPTION', match);
    //   console.log(
    //     `StripeProduct id ${product.id} matches Subscription Product id ${match.id}`
    //   );
    // } else {
    //   console.log(`No match found for StripeProduct id ${product.id}`);
    // }
    return !match;
  });

  const handleChange = async (selectedOption) => {
    console.log(selectedOption.id);
    setProductId(selectedOption.id);
    try {
      setIsLoading(true);
      const response = await getStripePrices();
      const data = await response.data.data;
      console.log('PRICES', data);
      const matchedPrices = data.filter(
        (price) => price.product === selectedOption.id
      );
      console.log('matchedResult', matchedPrices);

      if (matchedPrices.length) {
        setMatchedPrice(matchedPrices);
        setPackagePrice(
          matchedPrices.map((price, index) => ({
            price_id: price.id,
            price: price.unit_amount / 100,
            name: index === 0 ? 'Monthly' : 'Annually',
          }))
        );
        matchedPrices.forEach(
          (price, index) =>
            console.log(
              `Matched price: ${price.unit_amount / 100} (${
                index === 0 ? 'Monthly' : 'Annually'
              })`
            )
          // console.log(`Matched price: ${price.unit_amount / 100}`)
        );
      } else {
        // setMatchedPrice(null);
        console.log(
          `No matched price found for product id ${selectedOption.id}`
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    console.log(currentDataIndex);
    let previousDataIndex = (page - 1) * perPage;
    console.log(previousDataIndex);
    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });
    console.log('currentPageData', currentPageDataArray);
    setCurrentPageData(currentPageDataArray);
  }
  let filteredData = data.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.business.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, currentPage]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    fetchSubscriptionData();
  }, []);
  const defaultDescription = '';
  async function addSubscription(e) {
    let token = await getToken();
    e.preventDefault();
    let data = {
      name,
      business,
      packages: packagePrice,
      services: descriptions.map((desc) => ({
        description: desc,
      })),
      // services: [{ description, valid: valid }],
      product_id: productId,
    };
    console.log(data);
    // if (!name || !packages || !descriptions.length) {
    //   Swal.fire({
    //     title: 'Error!',
    //     text: 'Please Fill Out The Form',
    //     icon: 'error',
    //     confirmButtonText: 'OK',
    //   });
    // } else {
    await fetch(`${ApiRoute.createSubscription}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      console.warn('resp', resp);

      resp.json().then((result) => {
        console.warn('result', result);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Added',
          showConfirmButton: false,
          timer: 1500,
        });
        fetchSubscriptionData();
        closeModal();
        setName('');
        setDescriptions([defaultDescription]);
        setDescription('');
        setMatchedPrice('');
        setBusiness('');
        setValid('');
        setPackagePrice('');
      });
    });
    // }
  }
  async function getByID(id) {
    try {
      const response = await getSubscriptionById(id);
      console.log('RESPONSE FROM SUBSCRIPTION BY ID', response.data);
      openModalForUpdate(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteSubscription(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn(id);
        fetch(`${ApiRoute.deleteSubscription}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);

            fetchSubscriptionData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  async function updateSubscription(e) {
    let token = await getToken();
    e.preventDefault();
    let data = {
      name,
      business,
      id: selectedId,
      packages: packagePrice,
      services: descriptions.map((desc) => ({
        description: desc,
      })),
    };
    console.log(data);
    // if (!name || !packages || !descriptions.length) {
    //   Swal.fire({
    //     title: 'Error!',
    //     text: 'Please Fill Out The Form',
    //     icon: 'error',
    //     confirmButtonText: 'OK',
    //   });
    // } else {
    await fetch(`${ApiRoute.updateSubscription}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    }).then((resp) => {
      console.warn('resp', resp);

      resp.json().then((result) => {
        console.warn('result', result);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Updated',
          showConfirmButton: false,
          timer: 1500,
        });
        fetchSubscriptionData();
        closeModal();
        setName('');
        setDescriptions([defaultDescription]);
        setDescription('');
        setMatchedPrice('');
        setBusiness('');
        setValid('');
        setPackagePrice('');
      });
    });
    // }
  }

  const openModalForUpdate = async (data) => {
    setMode('update');
    setIsOpen(true);
    setSelectedId(data._id);
    setName(data.name);
    setDescriptions(data.services.map((service) => service.description));
    setBusiness(data.business);
    setPackagePrice(data.packages);
    console.log(data.packages);
  };

  const openModal = () => setIsOpen(true);
  // const closeModal = () => setIsOpen(false);
  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setBusiness('');
    setPackages('');
    setPackagePrice('');
    setDescriptions([defaultDescription]);
    setDescription('');
    setProductId('');
    // Reset other fields as needed
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* {data.slice(1).map((item) => (
          <div key={item._id}>
            <p>Name: {item.name}</p>
            <p>Packages:</p>
            {item.packages.map((product) => (
              <div key={product._id}>
                <p>Price: {product.price_id}</p>
                <p>Name: {product.name}</p>
                <p>Price: {product.price}</p>
              </div>
            ))}
          </div>
        ))} */}

        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {mode === 'add' ? 'Add New Subscription' : 'Update Subscription'}
            </Modal.Title>
            {/* <Modal.Title>Add New Subscription</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <input type="hidden" />
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Name</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <small class="form-control-feedback"></small>
                  </div>
                  <div class="form-group ">
                    <label for="title">Business</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      className="form-control"
                      value={business}
                      onChange={(e) => {
                        setBusiness(e.target.value);
                      }}
                    />
                    <small class="form-control-feedback"></small>
                  </div>
                  {/* <div class="form-group ">
                    <label for="title">Packages</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      className="form-control"
                      value={packages}
                      onChange={(e) => {
                        setPackages(e.target.value);
                      }}
                    />
                  </div> */}
                  <div>
                    <label htmlFor="title" className="mr-2">
                      Description
                    </label>
                    {descriptions.map((desc, index) => (
                      <div key={index} className="form-group  d-flex mt-1">
                        <input
                          type="text"
                          className="form-control"
                          value={desc}
                          onChange={(e) => {
                            const updatedDescriptions = [...descriptions];
                            updatedDescriptions[index] = e.target.value;
                            setDescriptions(updatedDescriptions);
                          }}
                        />
                        {index === 0 ? (
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary btn-rounded mx-1"
                              onClick={handleAddField}
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger btn-rounded mx-1"
                              onClick={() => handleRemoveField(index)}
                            >
                              -
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* <div class="form-group ">
                    <label htmlFor="title">Description</label>

                    <input
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div> */}
                  <div className="customFormGrp">
                    {mode === 'add' ? (
                      <>
                        <label className="fw-bold">
                          Select Subscription Type
                          <span className="redDot">*</span>
                        </label>
                        <div className="formControlWrap ">
                          <Select
                            id="categories"
                            name="select_category"
                            className="vendorprofile"
                            required
                            onChange={handleChange}
                            options={options}
                            isLoading={isLoading}
                          />
                        </div>
                      </>
                    ) : null}
                    {/* <label className="fw-bold">
                      Select Subscription Type<span className="redDot">*</span>
                    </label>
                    <div className="formControlWrap ">
                      <Select
                        id="categories"
                        name="select_category"
                        className="vendorprofile"
                        required
                        onChange={handleChange}
                        options={options}
                        isLoading={isLoading}
                      />
                    </div> */}
                    {packagePrice ? (
                      packagePrice.map((price, index) => (
                        <p key={index}>
                          {price.name}: {price.price}
                        </p>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={
                          mode === 'add' ? addSubscription : updateSubscription
                        }
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        {mode === 'add' ? 'Add' : 'Update'}
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                      {/* <button
                        onClick={addSubscription}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Add
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Container fluid>
          <Breadcrumbs
            title="Subscriptions"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col lg="12">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    // onClick={handleCustomerClick}
                    onClick={openModalForAdd}
                  >
                    <i className="mdi mdi-plus me-1" /> Add Subscription
                  </Button>
                </div>
              </Col>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Search </h4>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Subscriptions"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Card>
                <CardBody className="overflow-auto">
                  {isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '200',
                      }}
                    >
                      <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                      />
                    </div>
                  ) : (
                    <table className="table table-hover table-light bg-light text-dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th></th>
                          <th>Business</th>
                          {/* <th></th> */}

                          {/* <th>Product Id</th> */}
                          <th>Monthly</th>
                          <th>Yearly</th>
                          <th></th>
                          <th></th>
                          <th>Description</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageData?.map((emp, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{emp.name}</td>
                            <td></td>
                            {emp.business ? (
                              <td>{emp.business}</td>
                            ) : (
                              <td>none</td>
                            )}
                            {/* <td></td> */}
                            {/* {emp.product_id ? (
                              <td>{emp.product_id}</td>
                            ) : (
                              <td>null</td>
                            )} */}

                            {emp.packages.map((data, check) => {
                              return (
                                <>
                                  {data.price ? (
                                    <td check={check}>{data.price}</td>
                                  ) : (
                                    <td>None</td>
                                  )}
                                </>
                              );
                            })}
                            {emp.services.map((data, s) => {
                              return (
                                <>
                                  <td key={s}>{data.description}</td>
                                </>
                              );
                            })}
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <Button
                                onClick={() => {
                                  getByID(emp.id);
                                  openModalForUpdate();
                                }}
                                className="bg-primary btn-fixed  mx-2"
                              >
                                <i class="ri-edit-box-line "></i>
                              </Button>

                              <Button
                                onClick={() => deleteSubscription(emp.id)}
                                className="bg-danger btn-fixed mx-2"
                              >
                                <i class="ri-delete-bin-6-fill"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </CardBody>
              </Card>
              <Row>
                <Col lg="3">
                  <div className="btn-group mx-4">
                    <select
                      className="bg-secondary text-light"
                      value={perPage}
                      onChange={handleSelect}
                      style={{
                        fontSize: '1.4em',
                        padding: '1.5px',
                      }}
                    >
                      {perPageOptions.map((option, check) => (
                        <option key={check} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg="9">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel="..."
                    pageCount={totalPages}
                    onPageChange={(page) => handlePageClick(page.selected + 1)}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'item active '}
                    disableInitialCallback={true}
                    initialPage={currentPage}
                    forcePage={currentPage}
                  />
                </Col>
              </Row>
              {/* <Col lg="3">
                <div className="btn-group mx-4">
                  <select
                    className="bg-secondary text-light"
                    value={perPage}
                    onChange={handleSelect}
                    style={{
                      fontSize: '1.4em',
                      padding: '1.5px',
                    }}
                  >
                    {perPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col lg="12">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel="..."
                  pageCount={totalPages}
                  onPageChange={(page) => handlePageClick(page.selected + 1)}
                  containerClassName={'pagination'}
                  previousLinkClassName={'pagination__link'}
                  nextLinkClassName={'pagination__link'}
                  disabledClassName={'pagination__link--disabled'}
                  activeClassName={'item active '}
                  disableInitialCallback={true}
                  initialPage={currentPage}
                  forcePage={currentPage}
                />
              </Col> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Subscription;
