import React from 'react';

import { useEffect, useState } from 'react';
import { getVendorById } from '../../services/VendorService';
import { Col, Row, Card, CardBody, Label, Input, Form } from 'reactstrap';
import { getToken } from '../../services/AuthService';
import { ApiRoute } from '../../util/constants';
// import { updateInfo } from '../../services/VendorService';
import Swal from 'sweetalert2';
const VendorProfileImage = (props) => {
  const [data, setData] = useState([]);
  const [image, setImage] = useState({});
  const [assets, setAssets] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  // const { imagePath, setImagePath } = useState([]);

  const fetchVendorData = async () => {
    try {
      const response = await getVendorById(props.id);
      // if (isMounted) {
      setAssets(response.data.assets);
      setData(response.data);
    } catch (error) {
      // }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVendorData();
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getVendorById(props.id);

        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  async function addSlider(e) {
    let token = await getToken();
    e.preventDefault();
    let data = { image };
    const formData = new FormData();

    formData.append('image', image);
    console.log(data);

    fetch(`${ApiRoute.uploadVendorProfileImage}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    }).then((resp) => {
      resp
        .json()
        .then((result) => {
          console.warn('RESPONSE from DATA', result);

          fetch(`${ApiRoute.updateVendorProfile}`, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({
              id: props.id,
              pic_path: result.imagePath,
            }),
          }).then((resp) => {
            console.warn('resp', resp);
            resp
              .json()
              .then((result) => {
                if (result?.success) {
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'SuccessFully Updated',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  fetchVendorData();
                }
              })

              .catch((e) => alert(e, 'NETWORK ERROR'));
          });
        })
        .catch((e) => alert(e, 'NETWORK ERROR'));
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Card>
            <CardBody>
              <div className="container ">
                <div className="row">
                  <div className="container">
                    <div>
                      <h4 className="gray">My Vendor Profile Image</h4>
                      <div className="dashboard-list-box-static bg-light-gray">
                        <div className="edit-profile-photo">
                          <div className="change-photo-btn">
                            <div className="photoUpload">
                              {data.pic_path ? (
                                <img
                                  className="mx-auto d-flex"
                                  height={200}
                                  width={'auto'}
                                  src={
                                    'https://massi-bucket.s3.amazonaws.com/' +
                                    data.pic_path
                                  }
                                  alt="Slider"
                                ></img>
                              ) : (
                                <img
                                  className="mx-auto d-flex"
                                  height={200}
                                  width={'auto'}
                                  src={
                                    'https://www.eventmassi.com/images/noImage.svg'
                                  }
                                  //   src={
                                  //     'https://massi-bucket.s3.amazonaws.com/' +
                                  //     data.imagePath
                                  //   }
                                  alt="Slider"
                                ></img>
                              )}
                            </div>
                            <div>
                              <Form onSubmit={addSlider}>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="basicpill-pancard-input18"
                                      >
                                        Upload Image
                                      </Label>
                                      <Input
                                        type="file"
                                        onChange={(e) => {
                                          setImage(e.target.files[0]);
                                        }}
                                        className="form-control"
                                        required
                                        id="basicpill-pancard-input18"
                                      />

                                      <button
                                        type="submit"
                                        className="btn btn-dark mt-3 fs-5 col-lg-3 col-12 rounded-4"
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VendorProfileImage;
