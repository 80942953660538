import React from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import { getAllKeywords } from '../../services/KeywordsService';
import { updateInfo } from '../../services/VendorService';
import { getVendorById } from '../../services/VendorService';
import Swal from 'sweetalert2';
import './VendorInformation.css';
import { Editor } from '@tinymce/tinymce-react';
const VendorInformation = (props) => {
  // const [keywordsOptions, setKeywordsOptions] = useState([]);
  // const [keywords, setKeywords] = useState([]);
  const [vendor, setVendor] = useState([]);

  const [description, setDescription] = useState([]);
  const [costly, setCostly] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  // const handleKeywordChange = (handleKeywordChange) => {
  //   let keywordData = handleKeywordChange;
  //   keywordData.map((data, index) => setKeywords(data.id));
  // };

  // const fetchKeywordData = async () => {
  //   try {
  //     const response = await getAllKeywords();
  //     const data = await response.data;

  //     if (data.length > 0) {
  //       data.map((keywords) => {
  //         return keywordsOptions.push({
  //           id: keywords.id,
  //           label: keywords.name,
  //         });
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const handleEditorChange = (content) => {
    setDescription(content);
  };

  const fetch = async () => {
    try {
      const response = await getVendorById(props.id);
      if (isMounted) {
        setDescription(response.data.description);
        // setKeywords(response.data.keywords);

        setCostly(response.data.costly);

        // response.data.keywords.map((data, index) => setVendor(data.name));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetch();
    return () => {
      setIsMounted(false);
    };
  }, []);

  async function updateByID(e) {
    e.preventDefault();

    try {
      // keywords;
      var result = await updateInfo(props.id, costly, description);
      if (result?.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'SuccessFully Updated',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Card>
            <CardBody>
              <div className="editProfileContentWrap ">
                <div className="container">
                  <form
                    //   onSubmit={handleSubmit}
                    className="generalFormWrap myInformationForm"
                  >
                    <h3 className="editFormHeading">
                      Tell us about your company &amp; services
                    </h3>
                    <div className="customFormGrp">
                      <label>Description</label>
                      <div className="formControlWrap">
                        <Editor
                          apiKey="1ausnzpznit2slgbdbs476puc2wt1bllt99rw6d6zvjflaqh"
                          // value={description}
                          value={description?.toString()}
                          onEditorChange={handleEditorChange}
                          init={{
                            height: 300,

                            menubar: false,
                            statusbar: false,
                            // plugins: [
                            //   'advlist autolink lists link image charmap print preview anchor',
                            //   'searchreplace visualblocks code fullscreen',
                            //   'insertdatetime media table paste code help wordcount',
                            //   'fontsize',
                            // ],
                            fontsize_formats:
                              '8pt 10pt 12pt 14pt 18pt 24pt 36pt',

                            toolbar:
                              'undo redo |formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | fontsize',
                          }}
                        />
                        {/* <textarea
                          className="customFormControl"
                          name="notes"
                          id="notes"
                          cols="30"
                          rows="10"
                          defaultValue={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        ></textarea> */}
                      </div>
                    </div>
                    {/* <div className="customFormGrp">
                      <label>Keywords </label>
                      <Select
                        placeholder={vendor}
                        options={keywordsOptions}
                        isMulti={true}
                        name="select_keywords"
                        isDisabled={keywordsOptions.length === 0 ? true : false}
                        className="vendorprofile w-75"
                        // defaultValue={keywords}
                        onChange={handleKeywordChange}
                      />
                    </div> */}
                    <div className="customFormGrp">
                      <label>Vendor Price</label>
                      <input
                        name="costly"
                        id="costly"
                        value={costly}
                        onChange={(e) => {
                          setCostly(e.target.value);
                        }}
                        // defaultValue={costly}
                      />
                      <div className="radio-wrapper">
                        <input
                          type="radio"
                          name="costly"
                          defaultValue={'1D'}
                          defaultChecked={costly === '1D'}
                          id="option-1"
                          onChange={(e) => {
                            setCostly('1D');
                          }}
                        />
                        <input
                          type="radio"
                          name="costly"
                          id="option-2"
                          defaultValue={'2D'}
                          defaultChecked={costly === '2D'}
                          onChange={(e) => {
                            setCostly('2D');
                          }}
                        />
                        <input
                          type="radio"
                          name="costly"
                          defaultValue={'3D'}
                          defaultChecked={costly === '3D'}
                          id="option-3"
                          onChange={(e) => {
                            setCostly('3D');
                          }}
                        />
                        <label htmlFor="option-1" className="option option-1">
                          <span>$</span>
                        </label>
                        <label htmlFor="option-2" className="option option-2">
                          <span>$$</span>
                        </label>
                        <label htmlFor="option-3" className="option option-3">
                          <span>$$$</span>
                        </label>
                      </div>
                    </div>

                    <div className="customFormGrp btnFormGrp">
                      <button
                        onClick={updateByID}
                        className="col-lg-3 col-12 editProfileSubmitBtn"
                      >
                        Update About Us
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </CardBody>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default VendorInformation;
