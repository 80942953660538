import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  Label,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Blocks } from 'react-loader-spinner';
import { getStates } from '../../services/StateService';
import { addStateAPI } from '../../services/StateService';
import { deleteStateAPI } from '../../services/StateService';
import { updateStates } from '../../services/StateService';
import { getStatesById } from '../../services/StateService';
import Moment from 'react-moment';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Swal from 'sweetalert2';

const EcommerceCheckout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [serialNumber, setSerialNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [code, setCode] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [isOpen2, setIsOpen2] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'States', link: '#' },
  ]);
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  async function handleAdd(e) {
    e.preventDefault();
    let data = { name, code };

    if (!name || !code) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        const response = await addStateAPI(data);

        if (response.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'State SuccessFully Added',
            showConfirmButton: false,
            timer: 1500,
          });
          fetchStateData();
          closeModal();
          setName('');
          setCode('');
        }
      } catch (error) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
    }
  }
  async function deleteState(id) {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        // in result you have the answer given by user from modal
        if (result.isConfirmed) {
          // if the user give yes answer then it means we need to call delete api
          const response = await deleteStateAPI(id);

          // delete api will return response which then further checked if it is tru or not
          if (response.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'State SuccessFully Deleted',
              showConfirmButton: false,
              timer: 1500,
            });
            fetchStateData();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'SERVER ERROR',
              showConfirmButton: false,
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  }

  const fetchStateData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getStates();
      const data = await response.data;

      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);

      setData(data);
      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    fetchStateData();
  }, []);
  let filteredData = data.filter((row) => {
    return (
      row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // return row.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);

  const handleClear = () => {
    setSearchTerm('');
    setCurrentPage(0);
    setTotalPages(0);
    setIsLoading(true);
    let pages = 0;
    let currentPageDataArray = [];
    if (data.length > 0) {
      pages = Math.ceil(data.length / perPage);
      data.map((row, i) => {
        if (i + 1 <= perPage) {
          currentPageDataArray.push(row);
        }
      });
    }
    setCurrentPageData(currentPageDataArray);
    setTotalPages(pages);
    setIsLoading(false);
  };

  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    let previousDataIndex = (page - 1) * perPage;

    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }

  async function getByID(id) {
    try {
      const response = await getStatesById(id);
      setPlaceholder(response.data);

      setCode(response.data.code);
      setName(response.data.name);
      openModal2();
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  }
  async function updateByID(e) {
    e.preventDefault();
    if (!name || !code) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await updateStates(placeholder.id, name, code);
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'State SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          setName('');
          setCode('');
          fetchStateData();
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'ERROR',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setName('');
    setCode('');
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);
    setName('');
    setCode('');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Name</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <small class="form-control-feedback"></small>
                  </div>
                  <div class="form-group ">
                    <label for="title">Code</label>

                    <input
                      type="text"
                      // placeholder="Enter Description"
                      className="form-control"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={handleAdd}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Add
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal show={isOpen2} onHide={closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>UPDATE State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Name</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      // placeholder={placeholder.name}
                      defaultValue={placeholder.name}
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <small class="form-control-feedback"></small>
                  </div>

                  <div class="form-group ">
                    <label for="title">Code</label>

                    <input
                      type="text"
                      // placeholder="Enter Description"
                      // placeholder={placeholder.code}
                      defaultValue={placeholder.code}
                      className="form-control"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={updateByID}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Update
                      </button>
                      <Button variant="secondary" onClick={closeModal2}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="States" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg="12">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={openModal}
                  >
                    <i className="mdi mdi-plus me-1" /> Add State
                  </Button>
                </div>
              </Col>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Search </h4>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            placeholder="Search State"
                            class="form-control"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            disabled={!data}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={handleClear}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '200',
                  }}
                >
                  <Blocks
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                  />
                </div>
              ) : (
                <Card>
                  <CardBody className="overflow-auto ">
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>CreatedAt</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.map((emp, key) => (
                            <tr key={key}>
                              <td>{currentPage * perPage + key + 1}</td>

                              <td className="w-25">{emp.name}</td>
                              <td className="w-25">{emp.code}</td>

                              <td className="w-25">
                                <Moment format="DD MMMM YYYY, dddd">
                                  {emp.createdAt}
                                </Moment>
                              </td>
                              <td>
                                <Button
                                  onClick={() => getByID(emp.id)}
                                  className="bg-primary mx-2 my-1 "
                                >
                                  <i class="ri-edit-box-line"></i>
                                </Button>

                                <Button
                                  onClick={() => deleteState(emp.id)}
                                  className="bg-danger mx-2 my-1"
                                >
                                  <i class="ri-delete-bin-6-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                  <Row>
                    <Col lg="3">
                      <div className="btn-group mx-4">
                        <select
                          className="bg-secondary text-light"
                          value={perPage}
                          onChange={handleSelect}
                          style={{
                            fontSize: '1.4em',
                            padding: '1.5px',
                          }}
                        >
                          {perPageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg="9">
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel="..."
                        pageCount={totalPages}
                        onPageChange={(page) =>
                          handlePageClick(page.selected + 1)
                        }
                        containerClassName={'pagination'}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'item active '}
                        disableInitialCallback={true}
                        initialPage={currentPage}
                        forcePage={currentPage}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceCheckout;
