import { ApiRoute, CONSTANTS } from '../util/constants';

export const login = async (email, password) => {
  let data = { email, password };
  let response = await fetch(`${ApiRoute.login}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not login: ${response}`);
};
// export const register = async (email, password) => {
//   let data = { email, password };
//   let response = await fetch(`${ApiRoute.register}`, {
//     method: 'POST',
//     body: JSON.stringify(data),
//     headers: { ...CONSTANTS.REQUEST_HEADERS },
//   });
//   response = await response.json();
//   if (response) return response;
//   throw new Error(`Could not login: ${response}`);
// };
export const getToken = async () => {
  let token = localStorage.getItem('authUser');

  if (token) {
    token = token;
    return token;
  }
  return null;
};
