import React, { useState, useEffect } from 'react';
import { getAllVendors } from '../../services/VendorService';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getToken } from '../../services/AuthService';
import { updatePick } from '../../services/VendorService';
import { ApiRoute } from '../../util/constants';

import Swal from 'sweetalert2';
import './pagination.css';

import { Blocks } from 'react-loader-spinner';

import { topVendors } from '../../services/VendorService';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import Select from 'react-select';
const Position = () => {
  const [data, setData] = useState([]);

  const [pick, setPick] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Vendors', link: '#' },
  ]);

  const handleVendorChange = (handleVendorChange) => {
    setSelectedVendor(handleVendorChange);
  };
  const fetchAllVendors = async () => {
    try {
      const response = await getAllVendors();
      const data = await response.data;
      if (data.length > 0) {
        data.map((vendors) => {
          return vendorOptions.push({
            id: vendors._id,
            label: vendors.business,
          });
        });
      }
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  const handlePositionChange = (e) => {
    setPick(e.target.value);
  };
  async function updateByID(e) {
    e.preventDefault();
    var vendorId = selectedVendor.id;

    var result = await updatePick(vendorId, pick);
    if (!selectedVendor || !selectedVendor.id) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please select a vendor',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (!pick) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please select a position',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    try {
      if (result?.success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Position SuccessFully Assigned',
          showConfirmButton: false,
          timer: 1500,
        });
        setSelectedVendor([]);
        setPick('');
        fetchVendorData();
      }
    } catch (err) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const fetchVendorData = async () => {
    try {
      setIsLoading(true);
      const response = await topVendors();
      const data = await response.data;

      setData(data);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    fetchVendorData();
    fetchAllVendors();
  }, []);

  async function deletePick(pick) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure You want to Remove This Vendor"s Position?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${ApiRoute.deletePosition}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ pick: pick }),
        }).then((result) => {
          result.json().then((resp) => {
            console.log(resp);
            fetchVendorData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Position SuccessFully Removed',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Vendors" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs="12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Vendor Name</h4>
                  <form>
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <div className="customFormGrp">
                          <Select
                            options={vendorOptions}
                            onChange={handleVendorChange}
                            isMulti={false}
                            value={selectedVendor}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <select
                          value={pick}
                          onChange={handlePositionChange}
                          name="position"
                          className="form-control select2 select2-hidden-accessible"
                        >
                          <option value="" data-select2-id="6">
                            Choose a Position
                          </option>

                          <option value="1">1 Position</option>
                          <option value="2">2 Position</option>
                          <option value="3">3 Position</option>
                          <option value="4">4 Position</option>
                          <option value="5">5 Position</option>
                          <option value="6">6 Position</option>
                          <option value="7">7 Position</option>
                          <option value="8">8 Position</option>
                          <option value="9">9 Position</option>
                          <option value="10">10 Position</option>
                          <option value="11">11 Position</option>
                          <option value="12">12 Position</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <button
                          onClick={updateByID}
                          type="submit"
                          className="btn btn-info btn-sm align-self-center"
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Card>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '200',
                    }}
                  >
                    <Blocks
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                    />
                  </div>
                ) : (
                  <CardBody className="overflow-auto">
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th>Position</th>
                            <th>Business</th>
                            <th>Email</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 &&
                            data
                              .sort((a, b) => a.pick - b.pick)
                              .map((emp, key) => (
                                <tr key={key}>
                                  {/* <td>{key + 1}</td> */}
                                  <td className="w-25">{emp.pick}</td>
                                  <td className="d-dlex">
                                    {emp.pic_path ? (
                                      <img
                                        src={
                                          'https://massi-bucket.s3.amazonaws.com/' +
                                          emp.pic_path
                                        }
                                        height="40"
                                        width="40"
                                        alt=""
                                        className="rounded rounded-5 mx-1 mr-2 "
                                      />
                                    ) : null}

                                    {emp.business}
                                  </td>
                                  <td className="w-25">{emp.email}</td>

                                  <td>
                                    <Button
                                      onClick={() => deletePick(emp.pick)}
                                      className="bg-danger mx-2 "
                                    >
                                      <i className="ri-delete-bin-6-fill"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Position;
