import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getallCategories = async () => {
  let token = await getToken();
  try {
    let response = await fetch(`${ApiRoute.getCategories}`, {
      method: 'GET',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        Authorization: 'Bearer ' + token,
      },
    });
    response = await response.json();
    if (response) return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCategoryById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getCategoryById}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Category');
};

export const updateCategory = async (
  id,
  title,

  keywords
) => {
  let token = await getToken();
  let data = { id, title, keywords };
  let response = await fetch(`${ApiRoute.updateCategory}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Category: ${response}`);
};

export const deleteCategory = async (id) => {
  let token = await getToken();
  try {
    const response = await fetch(`${ApiRoute.deleteCategory}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ id: id }),
    });
    return response.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
export const getVendorByCategory = async () => {
  let token = await getToken();
  try {
    let response = await fetch(`${ApiRoute.getVendorsByCategories}`, {
      method: 'GET',
      headers: {
        ...CONSTANTS.REQUEST_HEADERS,
        Authorization: 'Bearer ' + token,
      },
    });
    response = await response.json();
    if (response) return response;
  } catch (error) {
    throw new Error(error);
  }
};
