import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getAllAdmins = async () => {
  let token = await getToken();
  let response = await fetch(`${ApiRoute.getAdmins}`, {
    method: 'GET',

    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Admins: ${response}`);
};

export const getAdminById = async (id) => {
  let token = await getToken();
  var url = `${ApiRoute.getAdminById}`;
  if (id != null) {
    url += `?id=${id}`;
  } else {
    throw new Error('ID required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get Admin');
};

export const updateAdmin = async (
  id,
  firstName,
  phone,
  lastName,
  email,
  city,
  address,
  password
) => {
  let token = await getToken();
  let data = { id, firstName, phone, lastName, email, city, address, password };
  let response = await fetch(`${ApiRoute.updateAdmin}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not Update Admin: ${response}`);
};
