import { getallCategories } from '../../../services/CategoriesService';
import {
  deleteCategory,
  getCategoryById,
  updateCategory,
} from '../../../services/CategoriesService';
import Moment from 'react-moment';
import Select from 'react-select';
import { getToken } from '../../../services/AuthService';
import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Blocks } from 'react-loader-spinner';
import { ApiRoute } from '../../../util/constants';

import ReactPaginate from 'react-paginate';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { getAllKeywords } from '../../../services/KeywordsService';
const Category = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState(1);
  const [placeholder, setPlaceholder] = useState('');

  const [data, setData] = useState([]);
  const [description, setDescription] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [title, setTitle] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedKeywordId, setselectedKeywordId] = useState([]);
  const [categoryKeyword, setcategoryKeyword] = useState([]);
  const [defaultKeyword, setDefaultKeyword] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);

  const [remainingKeyword, setRemainingKeyword] = useState([[]]);

  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Categories', link: '#' },
  ]);
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };

  const fetchRemainingKeyword = async () => {
    try {
      const response = await getallCategories();

      console.log('RESPONSE', response.data);
      const keywordIds = response.data
        .filter((item) => item.keywords.length > 0)
        .map((item) => item.keywords.map((keyword) => keyword.id))
        .flat();

      console.log('NEW', keywordIds);
      setRemainingKeyword(keywordIds);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };

  const fetchCategoryData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getallCategories();
      const data = await response.data;

      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);

        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }

      setCurrentPageData(currentPageDataArray);
      setData(data);

      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };

  const fetchKeywordData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllKeywords();
      const data = await response.data;
      console.log('KEYWORDS', data);
      setKeywords(data);

      setIsLoading(false);
    } catch (error) {
      console.log(
        error,
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        })
      );
    }
  };

  const options = keywords
    .filter((keyword) => !remainingKeyword.includes(keyword.id))
    .map((keyword) => ({
      value: keyword.id,
      label: keyword.name,
    }));
  let filteredData = data.filter((row) => {
    return row.title.toLowerCase().includes(searchTerm.toLowerCase());
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  useEffect(() => {
    fetchCategoryData();
    fetchKeywordData();
    fetchRemainingKeyword();
  }, []);
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    console.log(currentDataIndex);
    let previousDataIndex = (page - 1) * perPage;
    console.log(previousDataIndex);
    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });
    console.log(currentPageDataArray);

    setCurrentPageData(currentPageDataArray);
  }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  async function handleAdd(e) {
    e.preventDefault();

    let token = await getToken();
    let data = { title, keywords: selectedKeywordId };
    console.log(data);
    if (!title) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      await fetch(`${ApiRoute.createCategory}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      }).then((resp) => {
        resp
          .json()
          .then((result) => {
            console.warn('result', result);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Category SuccessFully Added',
              showConfirmButton: false,
              timer: 1500,
            });
            fetchCategoryData();
            fetchRemainingKeyword();
            closeModal();
            resetData();
          })
          .catch((e) =>
            alert(
              e,
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'SERVER ERROR',
                showConfirmButton: false,
              })
            )
          );
      });
    }
  }
  const resetData = () => {
    setselectedKeywordId([]);
    setTitle('');
    setcategoryKeyword([]);
  };
  async function deleteCat(id) {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        // in result you have the answer given by user from modal
        if (result.isConfirmed) {
          // if the user give yes answer then it means we need to call delete api
          const response = await deleteCategory(id);
          console.log('response', response.success);
          // delete api will return response which then further checked if it is tru or not
          if (response.success) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Category SuccessFully Deleted',
              showConfirmButton: false,
              timer: 1500,
            });
            fetchCategoryData();
            fetchRemainingKeyword();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'SERVER ERROR',
              showConfirmButton: false,
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  // const options = keywords.map((keyword) => ({
  //   value: keyword.id,
  //   label: keyword.name,

  // }));

  const handleChange = (selectedOptions) => {
    let selectedKeywordIds = selectedOptions.map((option) => option.value);
    let selectedKeywordName = selectedOptions.map((option) => option.label);
    setcategoryKeyword(selectedKeywordName);
    console.log('SELECTEDKEYWORD', selectedKeywordName);
    setselectedKeywordId(selectedKeywordIds);
  };

  async function getByID(id) {
    try {
      const response = await getCategoryById(id);
      console.log('response', response.data.keywords);
      setPlaceholder(response.data);
      setTitle(response.data.title);
      setDescription(response.data.description);
      setDefaultKeyword(response.data.keywords);
      openModal2();
    } catch (error) {
      console.log(error);
    }
  }

  const selectedKeywordsOpts = defaultKeyword.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  console.log('====================================');
  console.log(selectedKeywordsOpts, 'SELECT KEYWORDS OPTIONS');
  console.log('====================================');

  async function updateByID(e) {
    e.preventDefault();
    if (!title) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await updateCategory(
          placeholder.id,
          title,

          selectedKeywordId
        );
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Category SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          fetchCategoryData();
          fetchRemainingKeyword();
          resetData();
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'ERROR',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    resetData();
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);

    resetData();
  };

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleAdd} className="form-horizontal mt-4">
                <input type="hidden" />
                <div className="row">
                  <div className="col-12">
                    <div className="form-group ">
                      <label for="title">Title</label>
                      <input
                        type="text"
                        className="form-control "
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          );
                        }}
                      />
                    </div>
                  </div>
                  {/* <div class="col-12">
                    <div class="form-group ">
                      <label for="description">Description</label>
                      <textarea
                        class="form-control"
                        name="description"
                        cols="30"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      <small class="form-control-feedback"></small>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <div className="form-group ">
                      <label for="title">Select Keyword</label>
                      <Select
                        options={options}
                        isMulti
                        onChange={handleChange}
                      />

                      {/* <select onChange={handleChange}>
                        {keywords.map((keyword) => (
                          <option key={keyword.id} value={keyword.id}>
                            {keyword.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <div className="form-actions">
                      <div className="card-body">
                        <button type="submit" className="btn btn-success mx-1">
                          Add
                        </button>
                        <Button variant="secondary" onClick={closeModal}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          {/*  */}
          <Modal show={isOpen2} onHide={closeModal2}>
            <Modal.Header closeButton>
              <Modal.Title>Update Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={updateByID} className="form-horizontal mt-4">
                <input type="hidden" />
                <div className="row">
                  <div className="col-12">
                    <div className="form-group ">
                      <label for="title">Title</label>
                      <input
                        type="text"
                        className="form-control "
                        // placeholder={placeholder.title}
                        defaultValue={placeholder.title}
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div class="col-12">
                    <div class="form-group ">
                      <label for="description">Description</label>
                      <textarea
                        class="form-control"
                        name="description"
                        defaultValue={placeholder.description}
                        // placeholder={placeholder.description}
                        cols="30"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      <small class="form-control-feedback"></small>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <div className="form-group ">
                      <label for="title">Select Keyword</label>
                      <Select
                        options={options}
                        isMulti
                        // placeholder={categoryKeyword.id}
                        defaultValue={selectedKeywordsOpts}
                        onChange={handleChange}
                      />

                      {/* <select onChange={handleChange}>
                        {keywords.map((keyword) => (
                          <option key={keyword.id} value={keyword.id}>
                            {keyword.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div class="col-6">
                    <div class="form-group">
                      <div class="col-sm-4">
                        <label for="customCheck1">Status</label>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            name="is_active"
                            value="1"
                            checked
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-6 mt-3">
                    <div className="form-actions">
                      <div className="card-body">
                        <button type="submit" className="btn btn-success mx-1">
                          Update
                        </button>
                        <Button variant="secondary" onClick={closeModal2}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          {/*  */}
          <Container fluid>
            <Breadcrumbs title="Categories" breadcrumbItems={breadcrumbItems} />
            <Col sm="12">
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="success"
                  className="btn-rounded mb-2 me-2"
                  onClick={openModal}
                >
                  <i className="mdi mdi-plus me-1" /> Add New Category
                </Button>
              </div>
            </Col>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Search </h4>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Categories"
                          value={searchTerm}
                          onChange={(e) => handleSearch(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Button
                        className="btn btn-info"
                        onClick={() => setSearchTerm('')}
                        disabled={searchTerm === ''}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '200',
                  }}
                >
                  <Blocks
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                  />
                </div>
              ) : (
                <Card>
                  <CardBody className="overflow-auto">
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Keywords</th>
                            {/* <th>Description</th> */}

                            <th>CreatedAt</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData.map((item, key) => (
                            <tr key={key}>
                              <td>{currentPage * perPage + key + 1}</td>

                              {/* <td>{key + 1}</td> */}
                              <td>{item.title}</td>
                              <td>
                                {item.keywords && item.keywords.length > 0
                                  ? item.keywords.map((keywords, k) => (
                                      <td check={k}>{keywords.name + ','}</td>
                                    ))
                                  : null}
                              </td>
                              {/* <td>
                                {item.keywords.map((keywords, k) => (
                                  <td>{keywords.name + ','}</td>
                                ))}
                              </td> */}
                              {/* {item.description ? (
                                <td>{item.description}</td>
                              ) : (
                                <td>No Description</td>
                              )} */}

                              {/* {item.status === true ? (
                                <td>ACTIVE</td>
                              ) : (
                                <td>BLOCKED</td>
                              )} */}

                              <td>
                                {' '}
                                <Moment format="DD MMMM YYYY, dddd">
                                  {item.createdAt || item.created_at}
                                </Moment>
                              </td>

                              <td>
                                <Button
                                  onClick={() => getByID(item.id)}
                                  className="bg-primary mx-2 my-1"
                                >
                                  <i className="ri-edit-box-line"></i>
                                </Button>

                                <Button
                                  onClick={() => deleteCat(item.id)}
                                  className="bg-danger mx-2 my-1"
                                >
                                  <i className="ri-delete-bin-6-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                  <Row>
                    <Col lg="3">
                      <div className="btn-group mx-4">
                        <select
                          className="bg-secondary text-light"
                          value={perPage}
                          onChange={handleSelect}
                          style={{
                            fontSize: '1.4em',
                            padding: '1.5px',
                          }}
                        >
                          {perPageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg="9">
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel="..."
                        pageCount={totalPages}
                        onPageChange={(page) =>
                          handlePageClick(page.selected + 1)
                        }
                        containerClassName={'pagination'}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'item active '}
                        disableInitialCallback={true}
                        // initialPage={currentPage}
                        // forcePage={currentPage}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Category;

{
  /* <div class="col-6">
                    <div class="form-group">
                      <div class="col-sm-4">
                        <label for="customCheck1">Status</label>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                            name="is_active"
                            value="1"
                            checked
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */
}
