// import React, { Component } from 'react';
// import { Col, Card, CardBody } from 'reactstrap';
// import { getBrowseVendorList } from '../../services/VendorService';
// class MiniWidgets extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: null,
//       breadcrumbItems: [
//         { title: 'Event Massi', link: '/' },
//         { title: 'Dashboard', link: '#' },
//       ],
//     };
//   }

//   // async componentDidMount() {
//   //   const response = await getBrowseVendorList();
//   //   const data = await response.data.total;
//   //   console.log(data, 'DATA');
//   //   this.setState({ data });
//   // }

//   render() {
//     return (
//       <React.Fragment>
//         {this.props.reports.map((report, key) => (
//           <Col key={key} md={4}>
//             <Card>
//               <CardBody>
//                 <div className="d-flex">
//                   <div className="flex-1 overflow-hidden">
//                     <p className="text-truncate font-size-14 mb-2">
//                       {report.title}
//                     </p>
//                     <h4 className="mb-0">{report.value}</h4>
//                   </div>
//                   <div className="text-primary">
//                     <i className={report.icon + ' font-size-24'}></i>
//                   </div>
//                 </div>
//               </CardBody>

//               <CardBody className="border-top py-3">
//                 <div className="text-truncate">
//                   {/* className="badge badge-soft-success font-size-11 me-1" */}
//                   <span> {report.rate}</span>
//                   <span className="text-muted ms-2">{report.desc}</span>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         ))}
//       </React.Fragment>
//     );
//   }
// }

// export default MiniWidgets;

// import React, { Component } from 'react';
// import { Col, Card, CardBody } from 'reactstrap';
// import { getBrowseVendorList } from '../../services/VendorService';
// class MiniWidgets extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: null,
//       breadcrumbItems: [
//         { title: 'Event Massi', link: '/' },
//         { title: 'Dashboard', link: '#' },
//       ],
//     };
//   }
//   // async componentDidMount() {
//   //   const response = await getBrowseVendorList();
//   //   const data = await response.data.total;
//   //   console.log(data, 'DATA');
//   //   this.setState({ data });
//   // }
//   render() {
//     return (
//       <React.Fragment>
//         {this.props.reports.map((report, key) => (
//           <Col key={key} md={4}>
//             <Card>
//               <CardBody>
//                 <div className="d-flex">
//                   <div className="flex-1 overflow-hidden">
//                     <p className="text-truncate font-size-14 mb-2">
//                       {report.title}
//                     </p>
//                     <h4 className="mb-0">{report.value}</h4>
//                   </div>
//                   <div className="text-primary">
//                     <i className={report.icon + ' font-size-24'}></i>
//                   </div>
//                 </div>
//               </CardBody>
//               <CardBody className="border-top py-3">
//                 <div className="text-truncate">
//                   {/* className="badge badge-soft-success font-size-11 me-1" */}
//                   <span> {report.rate}</span>
//                   <span className="text-muted ms-2">{report.desc}</span>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         ))}
//       </React.Fragment>
//     );
//   }
// }
// export default MiniWidgets;

import React, { useState, useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { getBrowseVendorList } from '../../services/VendorService';
import { browseVendors } from '../../services/VendorService';
import { getAllAdmins } from '../../services/AdminService';
import { getAllContacts } from '../../services/ContactService';
import {
  getallCategories,
  getVendorByCategory,
} from '../../services/CategoriesService';
import { getVendorByStates } from '../../services/StateService';
import { getVendorByKeywords } from '../../services/KeywordsService';
import { Blocks } from 'react-loader-spinner';
// import SalesAnalytics from './SalesAnalytics';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
const MiniWidgets = () => {
  const [vendorData, setVendorData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [inquiriesData, setInquiriesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendorList, setVendorList] = useState(null);
  const [select, setSelect] = useState(null);
  const [nonPreffered, setnonPreferred] = useState(null);
  const [category, setCategory] = useState([]);
  const [states, setStates] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const fetchVendorData = async () => {
    try {
      setIsLoading(true);
      // const vendor = await getBrowseVendorList();
      // const category = await getallCategories();
      // const vendorByCategory = await getVendorByCategory();
      // const vendorByStates = await getVendorByStates();
      // const vendorByKeywords = await getVendorByKeywords();
      const [
        vendor,
        category,
        vendorByCategory,
        vendorByStates,
        vendorByKeywords,
        ,
      ] = await Promise.all([
        getBrowseVendorList(),
        getallCategories(),
        getVendorByCategory(),
        getVendorByStates(),
        getVendorByKeywords(),
        getAllAdmins(),
        getAllContacts(),
        browseVendors(),
      ]);
      console.log(vendorByKeywords.vendorByKeywords, 'vendorByKeywords');
      // vendorByCategory.vendorBycategories.map((vendor) => {
      //   console.log(vendor.count);
      // });
      setCategory(vendorByCategory.vendorBycategories);
      setStates(vendorByStates.vendorByStates);
      setKeywords(vendorByKeywords.vendorByKeywords);
      console.log('CATEGOIRES', category.data);
      const admin = await getAllAdmins();
      const inquiries = await getAllContacts();
      const vendorlist = await browseVendors();
      if (vendorlist?.users.length > 0) {
        var massiPrefferedVendorList = [];
        var massiSelectedVendorList = [];
        vendorlist?.users.map((vendor) => {
          return vendor.position === 0
            ? massiSelectedVendorList.push(vendor)
            : vendor.position === 1
            ? massiPrefferedVendorList.push(vendor)
            : null;
        });

        console.log('SELECTED VENDPORS', massiSelectedVendorList);
        console.log('Preferred VENDPORS', massiPrefferedVendorList);
        setVendorList(massiPrefferedVendorList.length);
        setSelect(massiSelectedVendorList.length);
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
      const vendorData = await vendor.data.total;
      const adminData = await admin.data.length;
      const inquiriesData = await inquiries.data.length;
      setVendorData(vendorData);
      setAdminData(adminData);
      setInquiriesData(inquiriesData);
      setnonPreferred(vendorData - massiPrefferedVendorList.length);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    fetchVendorData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: category.map((cat) => cat.title),

    series: category.map((cat) => cat.count),
    colors: ['#3498db', '#9b59b6', '#9b50b7', '#1abc9c'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
            show: true,
            scrollable: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,

      dropShadow: {
        enabled: false,
      },

      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      background: {
        enabled: true,
      },
    },
  };

  const keywordOptions = {
    chart: {
      type: 'donut',
    },
    labels: keywords.map((cat) => cat.name),

    series: keywords.map((cat) => cat.count),
    colors: ['#3498db', '#9b59b6', '#9b50b7', '#1abc9c'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
            show: true,
            scrollable: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,

      dropShadow: {
        enabled: false,
      },

      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      background: {
        enabled: true,
      },
    },
  };

  const data = [select, vendorList, nonPreffered, vendorData];
  const options = {
    chart: {
      type: 'pie',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
            show: true,
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,

      dropShadow: {
        enabled: false,
      },

      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      background: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    colors: ['#3498db', '#9b59b6', '#9b50b7', '#1abc9c'],

    series: data,
    labels: [
      'Selected Vendors ',
      'Preferred Vendors',
      'non-Preferred Vendors',
      'Total Vendors',
    ],
  };

  const Opts = {
    chart: {
      type: 'donut',
    },
    labels: states.map((state) => state.code),

    series: states.map((state) => state.count),
    colors: ['#3498db', '#9b59b6', '#9b50b7', '#1abc9c'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
            show: true,
            scrollable: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,

      dropShadow: {
        enabled: false,
      },

      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      background: {
        enabled: true,
      },
    },
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '200',
          }}
        >
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      ) : (
        <>
          <Col md={4}>
            <Card>
              <CardBody>
                <Link to="/vendor">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2 text-dark">
                        Total Vendors{' '}
                      </p>

                      <h4 className="mb-0">{vendorData}</h4>
                    </div>
                    <div className="text-primary">
                      <i
                        className={'ri-shield-user-fill' + ' font-size-24'}
                      ></i>
                    </div>
                  </div>
                </Link>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <Link to="/admins">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2 text-dark">
                        Admins
                      </p>
                      <h4 className="mb-0">{adminData}</h4>
                    </div>
                    <div className="text-primary">
                      <i className={'ri-admin-fill' + ' font-size-24'}></i>
                    </div>
                  </div>
                </Link>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <Link to="/contact">
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2 text-dark">
                        Total Inquiries
                      </p>

                      <h4 className="mb-0">{inquiriesData}</h4>
                    </div>

                    <div className="text-primary">
                      <i
                        className={' ri-question-answer-fill' + ' font-size-24'}
                      ></i>
                    </div>
                  </div>
                </Link>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Preferred Vendors
                    </p>
                    <h4 className="mb-0">{vendorList}</h4>
                  </div>
                  <div className="text-primary">
                    <i className={'ri-group-fill' + ' font-size-24'}></i>
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Non Preferred Vendors
                    </p>
                    <h4 className="mb-0">{nonPreffered}</h4>
                  </div>
                  <div className="text-primary">
                    <i className={'ri-group-2-fill' + ' font-size-24'}></i>
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Selected Vendors
                    </p>
                    <h4 className="mb-0">{select}</h4>
                  </div>
                  <div className="text-primary">
                    <i className={'ri-group-2-fill' + ' font-size-24'}></i>
                  </div>
                </div>
              </CardBody>

              <CardBody className="border-top py-3">
                <div className="text-truncate"></div>
              </CardBody>
            </Card>
          </Col>
          {/* <Card>
            <div id="donut-chart" className="apex-charts">
              <h2 className="card-title mt-4">OverView</h2>
              {data !== null ? (
                <ReactApexChart
                  options={options}
                  series={data}
                  type="donut"
                  height="500"
                />
              ) : (
                <p>Loading chart data...</p>
              )}
            </div>
          </Card> */}
          <Card>
            <Col xl={12}>
              <div id="donut-chart" className="apex-charts ">
                <h2 className="card-title mt-4">Vendors By Categories</h2>
                {data !== null ? (
                  <ReactApexChart
                    options={chartOptions}
                    series={chartOptions.series}
                    type={chartOptions.chart.type}
                    height="500"
                  />
                ) : (
                  <p>Loading chart data...</p>
                )}
              </div>
            </Col>
          </Card>
          <Card>
            <Col xl={12}>
              <div id="donut-chart" className="apex-charts ">
                <h2 className="card-title mt-4">Vendors By States</h2>
                {data !== null ? (
                  <ReactApexChart
                    options={Opts}
                    series={Opts.series}
                    type={Opts.chart.type}
                    height="500"
                  />
                ) : (
                  <p>Loading chart data...</p>
                )}
              </div>
            </Col>
          </Card>
          <Card>
            <Col xl={12}>
              <div id="donut-chart" className="apex-charts ">
                <h2 className="card-title mt-4">Vendors By Keywords</h2>
                {data !== null ? (
                  <ReactApexChart
                    options={keywordOptions}
                    series={keywordOptions.series}
                    type={keywordOptions.chart.type}
                    height="500"
                  />
                ) : (
                  <p>Loading chart data...</p>
                )}
              </div>
            </Col>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default MiniWidgets;
