import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getAllSubscribers = async () => {
  let token = await getToken();

  let response = await fetch(`${ApiRoute.getSubscribers}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Subscribers: ${response}`);
};
