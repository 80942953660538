import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Blocks } from 'react-loader-spinner';
import { getAllVendors } from '../../services/VendorService';
import { getToken } from '../../services/AuthService';
import { ApiRoute } from '../../util/constants';
import Swal from 'sweetalert2';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Vendor = () => {
  const [isMounted, setIsMounted] = useState(true);

  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [serialNumber, setSerialNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);

  const [breadcrumbItems] = useState([
    { title: 'Vendor Management', link: '#' },
    { title: 'Vendor', link: '#' },
  ]);
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
  };
  const fetchVendorData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllVendors();
      const data = await response.data;

      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }

      try {
        if (isMounted) {
          // your current code here
          setCurrentPageData(currentPageDataArray);
          setData(data);
          setTotalPages(pages);
        }
        setIsLoading(false);
      } catch (error) {
        if (isMounted)
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'SERVER ERROR',
            showConfirmButton: false,
          });
      }

      // setCurrentPageData(currentPageDataArray);
      // setData(data);
      // setTotalPages(pages);
      // setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  useEffect(() => {
    fetchVendorData();
    return () => setIsMounted(false);
  }, [perPage]);
  // useEffect(() => {
  //   fetchVendorData();
  // }, [perPage]);
  async function deleteVendor(_id) {
    let token = await getToken();
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        // in result you have the answer given by user from modal
        if (result.isConfirmed) {
          const deleteVendorUrl = ApiRoute.deleteVendorById(_id);
          // if the user give yes answer then it means we need to call delete api
          fetch(deleteVendorUrl, {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }).then((result) => {
            result.json().then((res) => {
              console.log('RESPONSE', res);
              if (res.success) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Vendor SuccessFully Deleted',
                  showConfirmButton: false,
                  timer: 1500,
                });
                if (isMounted) fetchVendorData();
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'SERVER ERROR',
                  showConfirmButton: false,
                });
              }
            });
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    console.log(currentDataIndex);
    let previousDataIndex = (page - 1) * perPage;
    console.log(previousDataIndex);
    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });
    console.log(currentPageDataArray);
    setCurrentPageData(currentPageDataArray);
  }
  const handleSearch = () => {
    if (data) {
      const filteredData = data.filter(
        (vendor) =>
          (vendor.business &&
            vendor.business.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (vendor.email &&
            vendor.email.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      const pages = Math.ceil(filteredData.length / perPage);
      setCurrentPageData(filteredData.slice(0, perPage));
      setTotalPages(pages);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    setCurrentPage(0);
    setTotalPages(0);
    setIsLoading(true);
    let pages = 0;
    let currentPageDataArray = [];
    if (data.length > 0) {
      pages = Math.ceil(data.length / perPage);
      data.map((row, i) => {
        if (i + 1 <= perPage) {
          currentPageDataArray.push(row);
        }
      });
    }
    setCurrentPageData(currentPageDataArray);
    setTotalPages(pages);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Vendors" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg="12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Search </h4>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search via Business"
                            className="form-control"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSearch();
                              }
                            }}
                            disabled={!data}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Button
                          className="btn btn-info mx-1"
                          onClick={handleSearch}
                          disabled={!data}
                        >
                          Search
                        </Button>
                        <Button
                          className="btn btn-info"
                          onClick={handleClear}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '200',
                  }}
                >
                  <Blocks
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                  />
                </div>
              ) : (
                <Card>
                  <CardBody className="overflow-auto ">
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Email</th>
                            <th>Business</th>
                            <th>Visits</th>
                            <th>Position</th>
                            <th>Phone</th>
                            {/* <th>Subscription</th> */}
                            {/* <th>Position</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.map((emp, key) => (
                            <tr key={key}>
                              <td>{currentPage * perPage + key + 1}</td>
                              {/* <td>{key + 1}</td> */}
                              <td>{emp.type}</td>
                              <td>{emp.email}</td>
                              <td className="d-dlex">
                                {emp.pic_path ? (
                                  <img
                                    src={
                                      'https://massi-bucket.s3.amazonaws.com/' +
                                      emp.pic_path
                                    }
                                    height="40"
                                    width="40"
                                    alt=""
                                    className="rounded rounded-5 mx-1 mr-2 "
                                  />
                                ) : (
                                  <img
                                    src={
                                      'https://www.eventmassi.com/images/noImage.svg'
                                    }
                                    height="40"
                                    width="40"
                                    alt=""
                                    className="rounded rounded-5 mx-1 mr-2 "
                                  />
                                )}

                                {emp.business}
                              </td>
                              <td>{emp.views}</td>
                              {emp.pick ? <td>{emp.pick}</td> : <td>N/A</td>}
                              <td>{emp.phone}</td>
                              {/* {emp.costly === '1D' ? (
                                <td>Basic</td>
                              ) : (
                                <td>Preferred</td>
                              )} */}
                              {/* <td>Free</td> */}

                              <td>
                                <Link to={`/form-wizard/${emp._id}`}>
                                  <Button className="bg-primary mx-2 ">
                                    <i className="ri-edit-box-line"></i>
                                  </Button>
                                </Link>
                                <Button
                                  onClick={() => deleteVendor(emp._id)}
                                  className="bg-danger mx-2"
                                >
                                  <i className="ri-delete-bin-6-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                  <Col lg="3">
                    <div className="btn-group mx-4">
                      <select
                        className="bg-secondary text-light"
                        value={perPage}
                        onChange={handlePerPageChange}
                        style={{
                          fontSize: '1.4em',
                          padding: '1.5px',
                        }}
                      >
                        {perPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>

                  <Col lg="12">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel="..."
                      pageCount={totalPages}
                      onPageChange={(page) =>
                        handlePageClick(page.selected + 1)
                      }
                      containerClassName={'pagination'}
                      previousLinkClassName={'pagination__link'}
                      nextLinkClassName={'pagination__link'}
                      disabledClassName={'pagination__link--disabled'}
                      activeClassName={'item active '}
                      disableInitialCallback={true}
                      initialPage={currentPage}
                      forcePage={currentPage}
                    />
                  </Col>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Vendor;
