import { ApiRoute, CONSTANTS } from '../util/constants';
import { getToken } from './AuthService';
export const getAllPayments = async () => {
  let token = await getToken();
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(`${ApiRoute.getPayments}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, Authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load Payments: ${response}`);
};

export const deletePayment = async (id) => {
  let token = await getToken();
  try {
    const response = await fetch(`${ApiRoute.deletePayment}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ _id: id }),
    });
    return response.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};
