// import React, { useState, useEffect } from 'react';
// import Moment from 'react-moment';
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   Card,
//   Form,
//   Label,
//   CardBody,
//   CardTitle,
// } from 'reactstrap';
// import ReactPaginate from 'react-paginate';
// import { Blocks } from 'react-loader-spinner';

// import { getContacts } from '../../services/VendorService';

// //Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';
// import Swal from 'sweetalert2';

// const Contact = () => {
//   const [isLoading, setIsLoading] = useState(false);

//   const [currentPageData, setCurrentPageData] = useState([]);
//   const [totalPages, setTotalPages] = useState(0);
//   const [perPage, setPerPage] = useState(10);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(0);
//   const [data, setData] = useState([]);
//   const perPageOptions = [10, 25, 50, 100];
//   const [breadcrumbItems] = useState([
//     { title: 'General', link: '#' },
//     { title: 'Contact Us', link: '#' },
//   ]);
//   const handleSelect = (e) => {
//     setPerPage(parseInt(e.target.value));
//   };
//   const fetchContactData = async () => {
//     try {
//       setCurrentPage(0);
//       setTotalPages(0);
//       setIsLoading(true);
//       const response = await getContacts();

//       const data = await response.data;

//       let pages = 0;
//       let currentPageDataArray = [];
//       if (data.length > 0) {
//         pages = Math.ceil(data.length / perPage);
//         data.map((row, i) => {
//           if (i + 1 <= perPage) {
//             currentPageDataArray.push(row);
//           }
//         });
//       }
//       setCurrentPageData(currentPageDataArray);
//       setData(data);
//       setTotalPages(pages);
//       setIsLoading(false);
//     } catch (error) {
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         title: 'SERVER ERROR',
//         showConfirmButton: false,
//       });
//     }
//   };
//   useEffect(() => {
//     fetchContactData();
//   }, []);
//   let filteredData = data.filter((row) => {
//     return (
//       row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       row.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       row.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       row.phone.toString().includes(searchTerm.toString()) ||
//       row.category.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   });
//   useEffect(() => {
//     if (searchTerm === '') {
//       const start = currentPage * perPage;
//       const end = start + perPage;
//       setCurrentPageData(data.slice(start, end));
//       setTotalPages(Math.ceil(data.length / perPage));
//     } else {
//       setCurrentPageData(filteredData);
//       setTotalPages(Math.ceil(filteredData.length / perPage));
//     }
//   }, [searchTerm, perPage, currentPage]);
//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   async function handlePageClick(page) {
//     setCurrentPage(page - 1);
//     let currentDataIndex = page * perPage;

//     let previousDataIndex = (page - 1) * perPage;

//     let currentPageDataArray = [];
//     data.map((row, i) => {
//       if (i >= previousDataIndex && i < currentDataIndex) {
//         currentPageDataArray.push(row);
//       }
//     });

//     setCurrentPageData(currentPageDataArray);
//   }

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Breadcrumbs title="Contacts" breadcrumbItems={breadcrumbItems} />

//           <Row>
//             <Col lg="12">
//               <div class="card">
//                 <div class="card-body">
//                   <h4 class="card-title">Search </h4>
//                   <form>
//                     <div class="row">
//                       <div class="col-md-6">
//                         <div class="form-group">
//                           <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Search Contacts"
//                             value={searchTerm}
//                             onChange={(e) => handleSearch(e)}
//                           />
//                         </div>
//                       </div>
//                       <div class="col-md-4">
//                         <Button
//                           className="btn btn-info"
//                           onClick={() => setSearchTerm('')}
//                           disabled={searchTerm === ''}
//                         >
//                           Clear
//                         </Button>
//                       </div>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               {isLoading ? (
//                 <div
//                   style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     height: '200',
//                   }}
//                 >
//                   <Blocks
//                     visible={true}
//                     height="80"
//                     width="80"
//                     ariaLabel="blocks-loading"
//                     wrapperStyle={{}}
//                     wrapperClass="blocks-wrapper"
//                   />
//                 </div>
//               ) : (
//                 <Card>
//                   <CardBody className="overflow-auto ">
//                     <div>
//                       <table className="table table-hover table-light bg-light text-dark ">
//                         <thead>
//                           <tr>
//                             <th>#</th>
//                             <th>Email</th>
//                             <th>First Name</th>
//                             <th>LastName</th>
//                             <th>Phone</th>
//                             <th>Message</th>
//                             <th>Category</th>
//                             {/* <th>Actions</th> */}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {currentPageData?.map((emp, key) => (
//                             <tr key={key}>
//                               <td>{key + 1}</td>
//                               <td>{emp.email}</td>
//                               <td>{emp.firstName}</td>
//                               <td>{emp.lastName}</td>
//                               <td>{emp.phone}</td>
//                               <td>{emp.message}</td>
//                               {emp.category ? (
//                                 <td>{emp.category}</td>
//                               ) : (
//                                 <td>N/A</td>
//                               )}

//                               {/* <td>
//                                 <Button
//                                   onClick={() => deleteState(emp.id)}
//                                   className="bg-danger mx-2"
//                                 >
//                                   <i class="ri-delete-bin-6-fill"></i>
//                                 </Button>
//                               </td> */}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   </CardBody>
//                   <Row>
//                     <Col lg="3">
//                       <div className="btn-group mx-4">
//                         <select
//                           className="bg-secondary text-light"
//                           value={perPage}
//                           onChange={handleSelect}
//                           style={{
//                             fontSize: '1.4em',
//                             padding: '1.5px',
//                           }}
//                         >
//                           {perPageOptions.map((option) => (
//                             <option key={option} value={option}>
//                               {option}
//                             </option>
//                           ))}
//                         </select>
//                       </div>
//                     </Col>
//                     <Col lg="9">
//                       <ReactPaginate
//                         previousLabel={'<'}
//                         nextLabel={'>'}
//                         breakLabel="..."
//                         pageCount={totalPages}
//                         onPageChange={(page) =>
//                           handlePageClick(page.selected + 1)
//                         }
//                         containerClassName={'pagination'}
//                         previousLinkClassName={'pagination__link'}
//                         nextLinkClassName={'pagination__link'}
//                         disabledClassName={'pagination__link--disabled'}
//                         activeClassName={'item active '}
//                         disableInitialCallback={true}
//                         initialPage={currentPage}
//                         forcePage={currentPage}
//                       />
//                     </Col>
//                   </Row>
//                   {/* <Col lg="3">
//                     <div className="btn-group mx-4">
//                       <select
//                         className="bg-secondary text-light"
//                         value={perPage}
//                         onChange={handleSelect}
//                         style={{
//                           fontSize: '1.4em',
//                           padding: '1.5px',
//                         }}
//                       >
//                         {perPageOptions.map((option) => (
//                           <option key={option} value={option}>
//                             {option}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </Col>
//                   <Col lg="12">
//                     <ReactPaginate
//                       previousLabel={'<'}
//                       nextLabel={'>'}
//                       breakLabel="..."
//                       pageCount={totalPages}
//                       onPageChange={(page) =>
//                         handlePageClick(page.selected + 1)
//                       }
//                       containerClassName={'pagination'}
//                       previousLinkClassName={'pagination__link'}
//                       nextLinkClassName={'pagination__link'}
//                       disabledClassName={'pagination__link--disabled'}
//                       activeClassName={'item active '}
//                       disableInitialCallback={true}
//                       initialPage={currentPage}
//                       forcePage={currentPage}
//                     />
//                   </Col> */}
//                 </Card>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Contact;

import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { Blocks } from 'react-loader-spinner';

import { getContacts } from '../../services/VendorService';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
const headers = [
  { label: 'Email', key: 'email' },
  // { label: 'id', key: '_id' },

  { label: 'FirstName', key: 'firstName' },
  { label: 'LastName', key: 'lastName' },

  { label: 'Phone', key: 'phone' },
  { label: 'Message', key: 'message' },
  { label: 'Category', key: 'category' },
];
const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataToDownload, setDataToDownload] = useState([]);
  const csvDownloadRef = useRef(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  const perPageOptions = [10, 25, 50, 100];
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Contact Us', link: '#' },
  ]);

  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  const fetchContactData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getContacts();

      const data = await response.data;

      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);
      setData(data);
      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };

  const fetchContacts = async () => {
    try {
      setIsLoading(true);
      const response = await getContacts();
      const data = await response.data;

      setDataToDownload(data);
      // setTimeout(() => {
      //   csvDownloadRef.current.link.click();
      // }, 500);
      csvDownloadRef.current.link.click();
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);
  let filteredData = data.filter((row) => {
    return (
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.phone.toString().includes(searchTerm.toString()) ||
      row.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  async function handlePageClick(page) {
    setCurrentPage(page - 1);

    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    let previousDataIndex = (page - 1) * perPage;

    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col lg="12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Search </h4>

                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Contacts"
                              value={searchTerm}
                              onChange={(e) => handleSearch(e)}
                            />
                            {/* <div class="input-group-append">
                              <CSVLink
                                data={dataToDownload}
                                headers={headers}
                                filename="VendorData.csv"
                                target="_blank"
                                ref={csvDownloadRef}
                              >
                                <button
                                  onClick={fetchContacts}
                                  className="btn btn-outline-secondary"
                                  type="button"
                                >
                                  Export Contacts Data
                                </button>
                              </CSVLink>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>

                    {/* <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Contacts"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                          />
                          <CSVLink
                            data={dataToDownload}
                            headers={headers}
                            filename="VendorData.csv"
                            target="_blank"
                            ref={csvDownloadRef}
                          />
                          <button
                            onClick={fetchContacts}
                            className="btn btn-primary mb-2"
                          >
                            EXPORT CONTACTS DATA
                          </button>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '200',
                  }}
                >
                  <Blocks
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                  />
                </div>
              ) : (
                <Card>
                  <CardBody className="overflow-auto ">
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>LastName</th>
                            <th>Phone</th>
                            <th>Message</th>
                            <th>Category</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.map((emp, key) => (
                            <tr key={key}>
                              <td>{currentPage * perPage + key + 1}</td>
                              {/* <td>{key + 1}</td> */}
                              <td>{emp.email}</td>
                              <td>{emp.firstName}</td>
                              <td>{emp.lastName}</td>
                              <td>{emp.phone}</td>
                              <td>{emp.message}</td>
                              {emp.category ? (
                                <td>{emp.category}</td>
                              ) : (
                                <td>N/A</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                  <Row>
                    <Col lg="3">
                      <div className="btn-group mx-4">
                        <select
                          className="bg-secondary text-light"
                          value={perPage}
                          onChange={handleSelect}
                          style={{
                            fontSize: '1.4em',
                            padding: '1.5px',
                          }}
                        >
                          {perPageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg="9">
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel="..."
                        pageCount={totalPages}
                        onPageChange={(page) =>
                          handlePageClick(page.selected + 1)
                        }
                        containerClassName={'pagination'}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'item active '}
                        disableInitialCallback={true}
                        initialPage={currentPage}
                        forcePage={currentPage}
                      />
                    </Col>
                  </Row>
                  {/* <Col lg="3">
                    <div className="btn-group mx-4">
                      <select
                        className="bg-secondary text-light"
                        value={perPage}
                        onChange={handleSelect}
                        style={{
                          fontSize: '1.4em',
                          padding: '1.5px',
                        }}
                      >
                        {perPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg="12">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel="..."
                      pageCount={totalPages}
                      onPageChange={(page) =>
                        handlePageClick(page.selected + 1)
                      }
                      containerClassName={'pagination'}
                      previousLinkClassName={'pagination__link'}
                      nextLinkClassName={'pagination__link'}
                      disabledClassName={'pagination__link--disabled'}
                      activeClassName={'item active '}
                      disableInitialCallback={true}
                      initialPage={currentPage}
                      forcePage={currentPage}
                    />
                  </Col> */}
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contact;
