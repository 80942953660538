import React, { useState, useEffect } from 'react';
import { getallFaqs } from '../../services/FAQService';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { postFaqs } from '../../services/FAQService';
import { getFaqsById } from '../../services/FAQService';
import { updateFaqs } from '../../services/FAQService';
import { Modal } from 'react-bootstrap';
import { Blocks } from 'react-loader-spinner';
import { getToken } from '../../services/AuthService';
import { ApiRoute } from '../../util/constants';
import ReactPaginate from 'react-paginate';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Faq = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [placeholder, setPlaceholder] = useState('');

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'FAQS', link: '#' },
  ]);
  const fetchFaqData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getallFaqs();
      const data = await response.data;
      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);

      setData(data);
      setTotalPages(pages);
      setData(data);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  };
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;

    let previousDataIndex = (page - 1) * perPage;

    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }
  let filteredData = data.filter((row) => {
    return (
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    fetchFaqData();
  }, []);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setDescription('');
    setTitle('');
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);
    setDescription('');
    setTitle('');
  };

  async function addFaq(e) {
    e.preventDefault();
    if (!title || !description) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await postFaqs(title, description);
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Faq SuccessFully Added',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal();
          setTitle('');
          setDescription('');

          fetchFaqData();
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
    }
  }

  async function deleteProduct(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn(id);
        fetch(`${ApiRoute.deleteFaq}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);
            fetchFaqData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Faq SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  async function getByID(id) {
    try {
      const response = await getFaqsById(id);
      setPlaceholder(response.data);

      setTitle(response.data.title);
      setDescription(response.data.description);
      openModal2();
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'SERVER ERROR',
        showConfirmButton: false,
      });
    }
  }
  async function updateByID(e) {
    e.preventDefault();
    if (!description || !title) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await updateFaqs(placeholder.id, title, description);
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Faq SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          setDescription('');
          setTitle('');
          fetchFaqData();
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Title</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      className="form-control"
                    />
                    <small class="form-control-feedback"></small>
                  </div>
                  <div class="form-group ">
                    <label for="title">Description</label>

                    <textarea
                      // type="text"
                      // placeholder="Enter Description"
                      className="form-control"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={addFaq}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Add
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal show={isOpen2} onHide={closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>UPDATE FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Title</label>

                    <input
                      type="text"
                      // placeholder="Enter Title"
                      // placeholder={placeholder.title}
                      defaultValue={placeholder.title}
                      className="form-control"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                    <small class="form-control-feedback"></small>
                  </div>
                  <div class="form-group ">
                    <label for="title">Description</label>

                    <textarea
                      // type="text"
                      // placeholder="Enter Description"
                      // placeholder={placeholder.description}
                      defaultValue={placeholder.description}
                      className="form-control"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button
                        onClick={updateByID}
                        type="submit"
                        class="btn btn-success mx-1"
                      >
                        Update
                      </button>
                      <Button variant="secondary" onClick={closeModal2}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="FAQs" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col lg="12">
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={openModal}
                    // onClick={this.handleCustomerClicks}
                  >
                    <i className="mdi mdi-plus me-1" /> Add FAQ
                  </Button>
                </div>
              </Col>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Search </h4>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Faqs"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <Card>
                <CardBody className="overflow-auto">
                  {isLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '200',
                      }}
                    >
                      <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                      />
                    </div>
                  ) : (
                    <div>
                      <table className="table table-hover table-light bg-light text-dark ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData.map((emp, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{emp.title}</td>
                              <td
                                style={{ width: '32rem' }}
                                className="text-wrap"
                              >
                                {emp.description}
                              </td>
                              <td>
                                <Button
                                  onClick={() => getByID(emp.id)}
                                  className="bg-primary mx-2 my-1 "
                                >
                                  <i class="ri-edit-box-line"></i>
                                </Button>

                                <Button
                                  onClick={() => deleteProduct(emp.id)}
                                  className="bg-danger mx-2 my-1"
                                >
                                  <i class="ri-delete-bin-6-fill"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Row>
                        <Col lg="3">
                          <div className="btn-group mx-1">
                            <select
                              className="bg-secondary text-light"
                              value={perPage}
                              onChange={handleSelect}
                              style={{
                                fontSize: '1.4em',
                                padding: '1.5px',
                              }}
                            >
                              {perPageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col lg="9">
                          <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel="..."
                            pageCount={totalPages}
                            onPageChange={(page) =>
                              handlePageClick(page.selected + 1)
                            }
                            containerClassName={'pagination'}
                            previousLinkClassName={'pagination__link'}
                            nextLinkClassName={'pagination__link'}
                            disabledClassName={'pagination__link--disabled'}
                            activeClassName={'item active '}
                            disableInitialCallback={true}
                            initialPage={currentPage}
                            forcePage={currentPage}
                          />
                        </Col>
                      </Row>
                      {/* <Col lg="3">
                        <div className="btn-group mx-4">
                          <select
                            className="bg-secondary text-light"
                            value={perPage}
                            onChange={handleSelect}
                            style={{
                              fontSize: '1.4em',
                              padding: '1.5px',
                            }}
                          >
                            {perPageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg="12">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel="..."
                          pageCount={totalPages}
                          onPageChange={(page) =>
                            handlePageClick(page.selected + 1)
                          }
                          containerClassName={'pagination'}
                          previousLinkClassName={'pagination__link'}
                          nextLinkClassName={'pagination__link'}
                          disabledClassName={'pagination__link--disabled'}
                          activeClassName={'item active '}
                          disableInitialCallback={true}
                          initialPage={currentPage}
                          forcePage={currentPage}
                        />
                      </Col> */}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Faq;
