import React from 'react';
import { getToken } from '../../services/AuthService';
import { useEffect, useState } from 'react';
import { getVendorById } from '../../services/VendorService';
import { getDealById, updateDeal } from '../../services/DealsService';
import { Modal } from 'react-bootstrap';
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from 'reactstrap';
import './Deals.css';

import Moment from 'react-moment';
import { ApiRoute } from '../../util/constants';
import Swal from 'sweetalert2';
const DealsInformation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [deals, setDeals] = useState([]);
  const [placeholder, setPlaceholder] = useState([]);
  const [image, setImage] = useState({});
  const [imageUrl, setImageUrl] = useState(null);

  const [dealName, setDealName] = useState([]);
  const [dealDescription, setDealDescription] = useState([]);
  const [defaultImage, setDefaultImage] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const fetchVendorData = async () => {
    try {
      setIsLoading(true);
      const response = await getVendorById(props.id);

      if (isMounted) {
        setData(response.deals);
        setDeals(response.deals.id);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVendorData();
    return () => {
      setIsMounted(false);
    };
  }, []);

  let name = dealName;
  let description = dealDescription;
  async function addSlider(e) {
    let token = await getToken();
    e.preventDefault();

    let data = { image };
    const formData = new FormData();

    formData.append('image', image);

    if (!image) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Upload Image',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      fetch(`${ApiRoute.uploadVendorMedia}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      }).then((resp) => {
        resp
          .json()
          .then((result) => {
            console.warn('RESPONSE from DATA', result);
            let newAssets = { type: 'photo', url: result.imagePath };
            fetch(`${ApiRoute.createDeal}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
              body: JSON.stringify({
                id: props.id,
                assets: newAssets,
                name,
                description,
              }),
            }).then((resp) => {
              console.warn('resp', resp);
              resp
                .json()
                .then((result) => {
                  console.warn('result', result);
                  if (result?.success) {
                    fetchVendorData();
                    setDealDescription('');
                    setDealName('');
                    setImageUrl('');
                    setImage('');
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'SuccessFully Added',
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  }
                })
                .catch((e) => alert(e, 'NETWORK ERROR'));
            });
          })
          .catch((e) => alert(e, 'NETWORK ERROR'));
      });
    }
  }

  async function getByID(id) {
    try {
      const response = await getDealById(id);

      setDefaultImage(response.data.assets.map((deals, key) => deals.url));

      console.log('RESPONSE', response);
      setPlaceholder(response.data);

      openModal();
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteProduct(deals) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${ApiRoute.deleteDeal}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: deals }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deal SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
        fetchVendorData();
      }
    });
  }

  useEffect(() => {
    fetchVendorData();
  }, []);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setDealDescription('');
    setDealName('');
    setImageUrl('');
    setImage('');
  };

  async function updateByID(e) {
    let token = await getToken();
    e.preventDefault();

    let data = { image };
    const formData = new FormData();

    formData.append('image', image);

    if (!image) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Upload Image',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      fetch(`${ApiRoute.uploadVendorMedia}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: formData,
      }).then((resp) => {
        resp
          .json()
          .then((result) => {
            console.warn('RESPONSE from DATA', result);
            let newAssets = { type: 'photo', url: result.imagePath };
            fetch(`${ApiRoute.updateDeal}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
              body: JSON.stringify({
                id: placeholder.id,
                assets: newAssets,
                name,
                description,
              }),
            }).then((resp) => {
              console.warn('resp', resp);
              resp
                .json()
                .then((result) => {
                  console.warn('result', result);
                  if (result?.success) {
                    fetchVendorData();
                    setDealDescription('');
                    setDealName('');
                    setImageUrl('');
                    setImage('');
                    closeModal();
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'SuccessFully Updated',
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  }
                })
                .catch((e) => alert(e, 'NETWORK ERROR'));
            });
          })
          .catch((e) => alert(e, 'NETWORK ERROR'));
      });
    }
  }
  const resetFields = () => {
    setDealName('');
    setDealDescription('');
    setImage(null);
    setImageUrl('');
  };
  return (
    <React.Fragment>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal mt-4">
            <div className="row">
              <div className="col-12">
                <div className="form-group ">
                  <label for="title">Name</label>
                  <input
                    type="text"
                    className="form-control "
                    name="title"
                    defaultValue={placeholder.name}
                    onChange={(e) => {
                      setDealName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group ">
                  <label for="title">Description</label>
                  <input
                    type="text"
                    className="form-control "
                    name="description"
                    defaultValue={placeholder.description}
                    onChange={(e) => {
                      setDealDescription(e.target.value);
                    }}
                  />
                </div>
                {/* <img
                  className=""
                  height={100}
                  width={'auto'}
                  src={'https://massi-bucket.s3.amazonaws.com/' + defaultImage}
                  alt="Slider"
                ></img> */}
                <div className="form-group">
                  <label>Upload Image</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        setImageUrl(URL.createObjectURL(e.target.files[0]));
                      }}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="form-group">
                  {imageUrl && (
                    <img
                      height={20}
                      src={imageUrl}
                      alt="Selected "
                      className="img-thumbnail"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-3">
                <div className="form-actions">
                  <div className="card-body">
                    <button
                      onClick={updateByID}
                      className="btn btn-success mx-1"
                    >
                      Update
                    </button>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <div>
        {data.length ? (
          <div className="card">
            <div className="card-body">
              <div className="d-flex p-10 no-block">
                <div className="align-slef-center">
                  <div>
                    <h6 className="text-muted m-b-0">Total Deals</h6>
                    <h2 className="m-b-0"> {data.length}</h2>
                  </div>
                </div>
                <div className="align-self-center display-6 ml-auto">
                  <i className="text-success icon-Target-Market"></i>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="mx-auto text-center">NO DEALS FOUND</h3>
        )}
        <div className="container">
          <div className="card">
            <div className="card-header">Add a New Deal</div>
            <div className="card-body">
              <form className="form" onSubmit={addSlider}>
                <div className="form-group">
                  <label className="">Deal Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={dealName}
                    onChange={(e) => {
                      setDealName(e.target.value);
                    }}
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <input
                    type="text"
                    value={dealDescription}
                    onChange={(e) => {
                      setDealDescription(e.target.value);
                    }}
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label>Upload Image</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        setImageUrl(URL.createObjectURL(e.target.files[0]));
                      }}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="form-group">
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Selected "
                      className="img-thumbnail"
                    />
                  )}
                </div>

                <div className="form-group">
                  <button className="btn btn-primary mx-1 mt-3">
                    Upload Now!
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mt-3"
                    onClick={resetFields}
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="dealsFormWrap">
          <form className="dealsForm" onSubmit={addSlider}>
            <div className="dealFormGrp">
              <label className="">Deal Name</label>
              <input
                type="text"
                className="dealFormControl"
                value={dealName}
                onChange={(e) => {
                  setDealName(e.target.value);
                }}
                placeholder=""
              />
            </div>
            <div className="dealFormGrp">
              <label>Description</label>
              <input
                type="text"
                value={dealDescription}
                onChange={(e) => {
                  setDealDescription(e.target.value);
                }}
                className="dealFormControl"
                placeholder=""
              />
            </div>
            <div className="upload-btn-wrapper">
              <button className="btn">
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/img-upload-icon.aa027acc.svg"
                  alt=""
                />
              </button>
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
                name="myfile"
                className="upload_deal_image"
                accept="image/*"
              />
            </div>
            <div className="dealFormGrp dealBtnGrp">
              <button className="dealUploadBtn">Upload Now!</button>
            </div>
          </form>
        </div> */}
        <Row>
          <div className="container ">
            <div className="row">
              <div className="container">
                <div className="dashboard-list-box ">
                  <div>
                    {data.map((deals, key) => (
                      <div key={key}>
                        {deals.assets.length > 0 ? (
                          <div>
                            {deals.assets.map((assets, map) => (
                              <div key={map}>
                                <Card className="bg-light text-dark">
                                  {/* <div className="text-left">
                                    <img
                                      className=""
                                      height={50}
                                      width={'auto'}
                                      src={
                                        'https://massi-bucket.s3.amazonaws.com/' +
                                        assets.url
                                      }
                                      alt="Slider"
                                    />
                                    <h3 className="text-dark">{deals.name}</h3>
                                    <p className="text-dark">
                                      {deals.description}
                                    </p>
                                    <Moment format="DD MMMM YYYY, dddd">
                                      {deals.createdAt}
                                    </Moment>
                                  </div> */}
                                  {/* 
                                  <CardBody className="d-flex justify-content-between">
                                    <div></div>
                                    <span>
                                      <Button
                                        onClick={() => getByID(deals.id)}
                                        className="bg-primary mx-1  "
                                      >
                                        <i class="ri-edit-box-line"></i>
                                      </Button>
                                      <Button
                                        onClick={() => deleteProduct(deals.id)}
                                        className="bg-danger mx-1"
                                      >
                                        <i class="ri-delete-bin-6-fill"></i>
                                      </Button>
                                    </span>
                                  </CardBody> */}

                                  <CardBody>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <img
                                          className=""
                                          height={100}
                                          width={'auto'}
                                          src={
                                            'https://massi-bucket.s3.amazonaws.com/' +
                                            assets.url
                                          }
                                          alt="Slider"
                                        ></img>
                                      </div>
                                      <div className="col-md-8">
                                        <h3
                                          dangerouslySetInnerHTML={{
                                            __html: deals.name,
                                          }}
                                        >
                                          {/* {deals.name} */}
                                        </h3>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: deals.description,
                                          }}
                                        >
                                          {/* {deals.description} */}
                                        </p>
                                        <Moment format="DD MMMM YYYY, dddd">
                                          {deals.createdAt}
                                        </Moment>
                                      </div>
                                      <Button
                                        onClick={() => getByID(deals.id)}
                                        className="bg-primary mx-1 my-4 w-25 mx-auto "
                                      >
                                        <i className="ri-edit-box-line"></i>
                                      </Button>
                                      <Button
                                        onClick={() => deleteProduct(deals.id)}
                                        className="bg-danger mx-2 my-1 my-4 w-25 mx-auto "
                                      >
                                        <i className="ri-delete-bin-6-fill"></i>
                                      </Button>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DealsInformation;
