import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './pag.css';
import Swal from 'sweetalert2';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Blocks } from 'react-loader-spinner';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { getToken } from '../../services/AuthService';
import {
  getAllKeywords,
  getKeywordById,
  updateKeywords,
} from '../../services/KeywordsService';
import { ApiRoute } from '../../util/constants';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const EmailRead = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [serialNumber, setSerialNumber] = useState(1);
  const [placeholder, setPlaceholder] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [name, setName] = useState('');

  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [breadcrumbItems] = useState([
    { title: 'General', link: '#' },
    { title: 'Keywords', link: '#' },
  ]);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setPerPage(parseInt(e.target.perPage.value));
  // };
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };

  const fetchKeywordData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllKeywords();
      const data = await response.data;
      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);
      setData(data);
      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      console.log(
        error,
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        })
      );
    }
  };
  let filteredData = data.filter((row) => {
    return row.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);

  useEffect(() => {
    fetchKeywordData();
  }, []);

  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    setSerialNumber((page - 1) * perPage + 1);
    let previousDataIndex = (page - 1) * perPage;

    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setName('');
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);
    setName('');
  };
  async function handleAdd(e) {
    e.preventDefault();
    let data = { name };
    console.log(data);
    if (!name) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      await fetch(`${ApiRoute.createKeywords}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((resp) => {
        resp.json().then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Keyword SuccessFully Created',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal();
          setName('');
          fetchKeywordData();
        });
      });
    }
  }

  async function handleDelete(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn(id);
        fetch(`${ApiRoute.deleteKeyword}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Keyword SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
        fetchKeywordData();
      }
    });
  }
  async function getByID(id) {
    try {
      const response = await getKeywordById(id);
      setPlaceholder(response.data);
      setName(response.data.name);

      openModal2();
    } catch (error) {
      console.log(error);
    }
  }

  async function updateByID(e) {
    e.preventDefault();
    if (!name) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await updateKeywords(placeholder.id, name);
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          setName('');
          fetchKeywordData();
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Keyword</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Name</label>
                    <input
                      type="text"
                      class="form-control "
                      name="title"
                      // value={name}
                      value={name.charAt(0).toUpperCase() + name.slice(1)}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button onClick={handleAdd} class="btn btn-success mx-1">
                        Add
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal show={isOpen2} onHide={closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Keyword</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-horizontal mt-4">
              <div class="row">
                <div class="col-12">
                  <div class="form-group ">
                    <label for="title">Name</label>
                    <input
                      type="text"
                      class="form-control "
                      name="title"
                      defaultValue={placeholder.name}
                      value={name.charAt(0).toUpperCase() + name.slice(1)}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                {/* <div class="col-6">
                  <div class="form-group">
                    <div class="col-sm-4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label class="custom-control-label" for="customCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="col-6 mt-3">
                  <div class="form-actions">
                    <div class="card-body">
                      <button onClick={updateByID} class="btn btn-success mx-1">
                        Update
                      </button>
                      <Button variant="secondary" onClick={closeModal2}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="Keywords" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={openModal}
                    // onClick={this.handleCustomerClicks}
                  >
                    <i className="mdi mdi-plus me-1" /> Add Keyword
                  </Button>
                </div>
              </Col>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Search </h4>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Via Title"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>

                {/* <form>
                  <label>
                    Items per page:
                    <select value={perPage} onChange={handleSelect}>
                      {perPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </label>
                </form> */}
                {/* <form onSubmit={handleSubmit}>
                  <label>Items per page:</label>
                  <input
                    type="number"
                    name="perPage"
                    min="1"
                    max="100"
                    value={perPage}
                    onChange={(e) => setPerPage(parseInt(e.target.value))}
                  />
                </form> */}
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '200',
                  }}
                >
                  <Blocks
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                  />
                </div>
              ) : (
                <div className=" mb-3">
                  <Card>
                    <CardBody>
                      <div>
                        <table className="table table-hover table-light bg-light text-dark">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th></th>
                              {/* <th>Status</th> */}

                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentPageData?.map((emp, key) => (
                              <tr key={key}>
                                <td>{currentPage * perPage + key + 1}</td>
                                {/* <td className="w-25">{key + 1}</td> */}
                                <td className="w-75">{emp.name}</td>
                                <td></td>
                                {/* {emp.status === true ? (
                                  <td className="w-50">ACTIVE</td>
                                ) : (
                                  <td className="w-50">BLOCKED</td>
                                )} */}
                                <td>
                                  <Button
                                    onClick={() => getByID(emp.id)}
                                    className="bg-primary mx-2 my-1 "
                                  >
                                    <i class="ri-edit-box-line"></i>
                                  </Button>

                                  <Button
                                    onClick={() => handleDelete(emp.id)}
                                    className="bg-danger mx-2 my-1"
                                  >
                                    <i class="ri-delete-bin-6-fill"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <Row>
                      <Col lg="3">
                        <div className="btn-group mx-4">
                          <select
                            className="bg-secondary text-light"
                            value={perPage}
                            onChange={handleSelect}
                            style={{
                              fontSize: '1.4em',
                              padding: '1.5px',
                            }}
                          >
                            {perPageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg="9">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakLabel="..."
                          pageCount={totalPages}
                          onPageChange={(page) =>
                            handlePageClick(page.selected + 1)
                          }
                          containerClassName={'pagination'}
                          previousLinkClassName={'pagination__link'}
                          nextLinkClassName={'pagination__link'}
                          disabledClassName={'pagination__link--disabled'}
                          activeClassName={'item active '}
                          disableInitialCallback={true}
                          // initialPage={currentPage}
                          forcePage={currentPage}
                        />
                      </Col>
                    </Row>

                    {/* <Col lg="12">
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel="..."
                        pageCount={totalPages}
                        onPageChange={(page) =>
                          handlePageClick(page.selected + 1)
                        }
                        containerClassName={'pagination'}
                        previousLinkClassName={'pagination__link'}
                        nextLinkClassName={'pagination__link'}
                        disabledClassName={'pagination__link--disabled'}
                        activeClassName={'item active '}
                        disableInitialCallback={true}
                        initialPage={currentPage}
                        forcePage={currentPage}
                      />
                    </Col>
                    <Col lg="5">
                      <select
                        value={perPage}
                        onChange={handleSelect}
                        style={{
                          padding: '0.5em',
                          fontSize: '1em',
                          borderRadius: '0.5em',
                          border: 'none',
                        }}
                      >
                        {perPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </Col> */}
                  </Card>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailRead;
