import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Container, CardBody } from 'reactstrap';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
import { Blocks } from 'react-loader-spinner';
import {
  getAllAdmins,
  getAdminById,
  updateAdmin,
} from '../../services/AdminService';
import { ApiRoute } from '../../util/constants';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getToken } from '../../services/AuthService';
const EmailInbox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [firstName, setfirstName] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const perPageOptions = [10, 25, 50, 100];
  const [currentPage, setCurrentPage] = useState(0);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [lastName, setlastName] = useState([]);
  const [password, setPassword] = useState([]);
  const [city, setCity] = useState([]);
  const [address, setAddress] = useState([]);

  const [breadcrumbItems] = useState([{ title: 'Admins', link: '#' }]);

  const fetchAdminData = async () => {
    try {
      setCurrentPage(0);
      setTotalPages(0);
      setIsLoading(true);
      const response = await getAllAdmins();
      const data = await response.data;
      let pages = 0;
      let currentPageDataArray = [];
      if (data.length > 0) {
        pages = Math.ceil(data.length / perPage);
        data.map((row, i) => {
          if (i + 1 <= perPage) {
            currentPageDataArray.push(row);
          }
        });
      }
      setCurrentPageData(currentPageDataArray);
      setData(data);
      setTotalPages(pages);
      setIsLoading(false);
    } catch (error) {
      console.log(
        error,
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        })
      );
    }
  };
  const handleSelect = (e) => {
    setPerPage(parseInt(e.target.value));
  };
  useEffect(() => {
    if (searchTerm === '') {
      const start = currentPage * perPage;
      const end = start + perPage;
      setCurrentPageData(data.slice(start, end));
      setTotalPages(Math.ceil(data.length / perPage));
    } else {
      setCurrentPageData(filteredData);
      setTotalPages(Math.ceil(filteredData.length / perPage));
    }
  }, [searchTerm, perPage, currentPage]);
  useEffect(() => {
    fetchAdminData();
  }, []);

  let filteredData = data.filter((row) => {
    return (
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.phone.toString().includes(searchTerm.toString())
    );
  });
  async function handlePageClick(page) {
    setCurrentPage(page - 1);
    let currentDataIndex = page * perPage;
    console.log(currentDataIndex);
    let previousDataIndex = (page - 1) * perPage;
    console.log(previousDataIndex);
    let currentPageDataArray = [];
    data.map((row, i) => {
      if (i >= previousDataIndex && i < currentDataIndex) {
        currentPageDataArray.push(row);
      }
    });

    setCurrentPageData(currentPageDataArray);
  }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  async function handleAdd(e) {
    let token = await getToken();
    e.preventDefault();
    let data = {
      firstName,
      phone,
      lastName,
      email,
      city,
      address,
      password,
    };
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (
      !firstName ||
      !phone ||
      !lastName ||
      !email ||
      !city ||
      !address ||
      !password
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else if (!validateEmail) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Enter Valid Email',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      await fetch(`${ApiRoute.createAdmin}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      }).then((resp) => {
        resp
          .json()
          .then((result) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Admin SuccessFully Created',
              showConfirmButton: false,
              timer: 1500,
            });
            fetchAdminData();
            closeModal();
            setfirstName('');
            setEmail('');
            setPhone('');
            setlastName('');
            setPassword('');
            setCity('');
            setAddress('');
          })
          .catch((e) =>
            alert(
              e,
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'SERVER ERROR',
                showConfirmButton: false,
              })
            )
          );
      });
    }
  }

  async function deleteAdmin(id) {
    let token = await getToken();
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn(id);
        fetch(`${ApiRoute.deleteAdmin}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify({ id: id }),
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp);
            fetchAdminData();
          });
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Admin SuccessFully Deleted',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  async function getByID(id) {
    try {
      const response = await getAdminById(id);
      setPlaceholder(response.data);
      setfirstName(response.data.firstName);
      setEmail(response.data.email);
      setPhone(response.data.phone);
      setlastName(response.data.lastName);
      setPassword(response.data.password);
      setCity(response.data.city);
      setAddress(response.data.address);
      openModal2();
    } catch (error) {
      console.log(
        error,
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        })
      );
    }
  }

  async function updateByID(e) {
    e.preventDefault();
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);
    if (
      !firstName ||
      !phone ||
      !lastName ||
      !email ||
      !city ||
      !address ||
      !password
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Fill Out The Form',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else if (!validateEmail) {
      Swal.fire({
        title: 'Error!',
        text: 'Please Enter Valid Email',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        var result = await updateAdmin(
          placeholder.id,
          firstName,
          phone,
          lastName,
          email,
          city,
          address,
          password
        );
        if (result?.success) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'SuccessFully Updated',
            showConfirmButton: false,
            timer: 1500,
          });
          closeModal2();
          fetchAdminData();
          setfirstName('');
          setEmail('');
          setPhone('');
          setlastName('');
          setPassword('');
          setCity('');
          setAddress('');
        }
      } catch (err) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'SERVER ERROR',
          showConfirmButton: false,
        });
      }
    }
  }

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setfirstName('');
    setEmail('');
    setPhone('');
    setlastName('');
    setPassword('');
    setCity('');
    setAddress('');
  };
  const openModal2 = () => setIsOpen2(true);
  const closeModal2 = () => {
    setIsOpen2(false);
    setfirstName('');
    setEmail('');
    setPhone('');
    setlastName('');
    setPassword('');
    setCity('');
    setAddress('');
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAdd} className="form-horizontal mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="form-group ">
                    <label for="title">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      onChange={(e) => {
                        setfirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      onChange={(e) => {
                        setlastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Phone</label>

                    <input
                      type="number"
                      // placeholder="Enter Title"
                      className="form-control"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">City</label>

                    <input
                      type="text"
                      className="form-control"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Address</label>

                    <input
                      type="text"
                      className="form-control"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Email</label>

                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-6">
                  <div className="form-group">
                    <div className="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label
                          className="custom-control-label"
                          for="customCheck1"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-6 mt-3">
                  <div className="form-actions">
                    <div className="card-body">
                      <button
                        onClick={handleAdd}
                        type="submit"
                        className="btn btn-success mx-1"
                      >
                        Add
                      </button>
                      <Button variant="secondary" onClick={closeModal}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {/*  */}
        <Modal show={isOpen2} onHide={closeModal2}>
          <Modal.Header closeButton>
            <Modal.Title>Update Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="form-group ">
                    <label for="title">First Name</label>
                    <input
                      type="text"
                      defaultValue={placeholder.firstName}
                      className="form-control"
                      // placeholder={placeholder.firstName}
                      value={firstName}
                      onChange={(e) => {
                        setfirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={placeholder.lastName}
                      // placeholder={placeholder.lastName}
                      value={lastName}
                      onChange={(e) => {
                        setlastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Phone</label>

                    <input
                      type="number"
                      // placeholder={placeholder.phone}
                      className="form-control"
                      value={phone}
                      defaultValue={placeholder.phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      defaultValue={placeholder.password}
                      // placeholder={placeholder.password}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">City</label>

                    <input
                      type="text"
                      className="form-control"
                      defaultValue={placeholder.city}
                      // placeholder={placeholder.city}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Address</label>

                    <input
                      type="text"
                      className="form-control"
                      defaultValue={placeholder.address}
                      // placeholder={placeholder.address}
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group ">
                    <label for="title">Email</label>

                    <input
                      type="email"
                      className="form-control"
                      defaultValue={placeholder.email}
                      // placeholder={placeholder.email}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-6">
                  <div className="form-group">
                    <div className="col-sm-4">
                      <label for="customCheck1">Status</label>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          name="is_active"
                          value="1"
                          checked
                        />
                        <label
                          className="custom-control-label"
                          for="customCheck1"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-6 mt-3">
                  <div className="form-actions">
                    <div className="card-body">
                      <button
                        onClick={updateByID}
                        type="submit"
                        className="btn btn-success mx-1"
                      >
                        Update
                      </button>
                      <Button variant="secondary" onClick={closeModal2}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Container fluid>
          <Breadcrumbs title="Admins" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xs={12}>
              <Col sm="12">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={openModal}
                    // onClick={this.handleCustomerClicks}
                  >
                    <i className="mdi mdi-plus me-1" /> Add Admin
                  </Button>
                </div>
              </Col>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Search </h4>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Admins"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Button
                          className="btn btn-info"
                          onClick={() => setSearchTerm('')}
                          disabled={searchTerm === ''}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className=" mb-3">
                <Card>
                  <CardBody className="overflow-auto">
                    {isLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: '200',
                        }}
                      >
                        <Blocks
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                        />
                      </div>
                    ) : (
                      <div>
                        <table className="table table-hover table-light bg-light text-dark ">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>CreatedAt</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentPageData?.map((emp, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{emp.firstName}</td>

                                <td>{emp.email}</td>
                                <td>{emp.phone}</td>
                                <td>
                                  <Moment format="DD MMMM YYYY, dddd">
                                    {emp.createdAt}
                                  </Moment>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => getByID(emp.id)}
                                    className="bg-primary mx-2 my-1"
                                  >
                                    <i className="ri-edit-box-line"></i>
                                  </Button>

                                  <Button
                                    onClick={() => deleteAdmin(emp.id)}
                                    className="bg-danger mx-2 my-1 "
                                  >
                                    <i className="ri-delete-bin-6-fill"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Row>
                          <Col lg="3">
                            <div className="btn-group mx-4">
                              <select
                                className="bg-secondary text-light"
                                value={perPage}
                                onChange={handleSelect}
                                style={{
                                  fontSize: '1.4em',
                                  padding: '1.5px',
                                }}
                              >
                                {perPageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col lg="9">
                            <ReactPaginate
                              previousLabel={'<'}
                              nextLabel={'>'}
                              breakLabel="..."
                              pageCount={totalPages}
                              onPageChange={(page) =>
                                handlePageClick(page.selected + 1)
                              }
                              containerClassName={'pagination'}
                              previousLinkClassName={'pagination__link'}
                              nextLinkClassName={'pagination__link'}
                              disabledClassName={'pagination__link--disabled'}
                              activeClassName={'item active '}
                              disableInitialCallback={true}
                              // initialPage={currentPage}
                              // forcePage={currentPage}
                            />
                          </Col>
                        </Row>
                        {/* <Col lg="3">
                          <div className="btn-group mx-4">
                            <select
                              className="bg-secondary text-light"
                              value={perPage}
                              onChange={handleSelect}
                              style={{
                                fontSize: '1.4em',
                                padding: '1.5px',
                              }}
                            >
                              {perPageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col lg="12">
                          <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel="..."
                            pageCount={totalPages}
                            onPageChange={(page) =>
                              handlePageClick(page.selected + 1)
                            }
                            containerClassName={'pagination'}
                            previousLinkClassName={'pagination__link'}
                            nextLinkClassName={'pagination__link'}
                            disabledClassName={'pagination__link--disabled'}
                            activeClassName={'item active '}
                            disableInitialCallback={true}
                            initialPage={currentPage}
                            forcePage={currentPage}
                          />
                        </Col> */}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailInbox;
